import React from "react";
import styled from "styled-components";

const Self = styled.section`
.container {
  background-color: red;
}
.jumbotron {
  background-color: #F8F8F5;
}
h4 {
  margin-top:0;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}
p {
  
  font-family: "Poppins";
}
`;

function SelfLeadership(props) {
  const { content} = props;
  return (
    <Self className="container">
      <div className="jumbotron">
        <h4 className="text-center">
        SELF-LEADERSHIP
        </h4>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
          <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content.content.self_leadership,
                    }}
                  />
          </div>
        </div>
        </div>
    </Self>
  );
}

export default SelfLeadership;
