import React from "react";
import { BackgroundLayerCopydImage } from "../images";
import CardElement from "../CardElement";

import styled from "styled-components";

const Char = styled.section`
  h4 {
    margin-top:0;
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  p {
    font-size: 100%;
    display: inline;
    @font-face {
      font-family: "Poppins SemiBold";
      src: local("Poppins SemiBold"),
        url(../../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
    }
    font-family: "Poppins SemiBold";
  }
  .jumbotron {
    background-color: #f8f8f5;
  }
`;
;
const warna={
  Yellow:'#f1c40f',
  Red:'#e74c3c',
  Orange:'#f39c12',
  'Light Green':'#81ecec',
  Green:'#27ae60',
  Tosca:'#16a085',
  Blue:'#3498db',
  Purple:'#9b59b6',
 
};
function Characteristic(props) {
  const { content } = props;
  return (
    <Char className="container">
      <div className="jumbotron">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h4 className="text-center">
              <strong>Karakteristik</strong>
            </h4>
            <div
                  dangerouslySetInnerHTML={{
                    __html: content.content.deskripsi_karakter,
                  }}
                />
          </div>
          <CardElement
            src={content.content.logo_url}
            alt={content.content.simbol}
            displayCircle="none"
            title={"Element : "+content.content.simbol}
            text={content.content.deskripsi_element}
            color={null}
          />
          <CardElement
            title={"Warna : " + content.content.warna}
            text={content.content.deskripsi_warna}
            color={warna[content.content.warna]}
          />
        </div>
      </div>
    </Char>
  );
}

export default Characteristic;
