import React from "react";
import Chart from "../Chart";
import styled from "styled-components";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Legend,
} from "recharts";
const Title = styled.section`
  h3,
  h4 {
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  p {
    font-size: 100%;
    display: inline;
    @font-face {
      font-family: "Poppins SemiBold";
      src: local("Poppins SemiBold"),
        url(../../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
    }
    font-family: "Poppins SemiBold";
  }
  .jumbotron {
    background-color: #f8f8f5;
  }
`;

function YourProfile(props) {
  const { content,result_data,max } = props;
  return (
    <Title className="container">
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h3 className="text-center title">Profile Anda</h3>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-6 col-md-6 col-sm-6 my-auto mx-auto">
          <div className="jumbotron">
          {result_data.length > 0 ? (
                  <RadarChart
                    key={Math.random() * 9999}
                    outerRadius={90}
                    width={300}
                    height={250}
                    data={result_data}
                    style={{ margin: "0 auto", textAlign: "center" }}
                  >
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis angle={30} domain={[0, max]} />
                    <Radar
                      name="Profile"
                      dataKey="score"
                      stroke={content.content.color}
                      fill={content.content.color}
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
          <div className="jumbotron">
            <div className="card">
              <h4 className="text-center">{content.content.title.toUpperCase()}</h4>
              <div
                  style={{ marginTop: 15 }}
                  dangerouslySetInnerHTML={{ __html: content.content.intro }}
                />
            </div>
          </div>
        </div>
      </div>
    </Title>
  );
}

export default YourProfile;
