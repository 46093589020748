import React from "react";
import CardConflict from "../CardConflict";
import styled from "styled-components";

const Conflict = styled.section`
  .jumbotron {
    background-color: #F8F8F5;
  }

  h4 {
    margin-top:0;
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  p {
   
    font-family: "Poppins";
  }

  span {
   
    font-family: "Poppins";
  }

  @media screen and (max-width: 420px) {
    h4 {
      margin-top:0;
    }
  }
`;

function ConflictProfile(props) {
  const {content} = props;
  return (
    <Conflict className="container">
      <div className="jumbotron">
      <h4 className="text-center">
        Konflik antara orang-orang yang berbeda profil
      </h4>
      <span
                      
                      dangerouslySetInnerHTML={{
                        __html: content.konflik,
                      }}
                    />
      <p>
        <h4>Primer</h4>{" "}
      </p>
      <div className="row clearfix">
      {content.konflik_primer.map((item, index) => {
        return (
          <div key={index} className="col-lg-6 col-md-6 col-sm-6">
          <div className="content">
            <CardConflict
              title={item.title}
              subtitle={"Kekuatan : " + item.kekuatan}
              text={item.dukungan}
            />
          </div>
        </div>
        );
      })};
       
       
      </div>
      <p>
        <h4>Sekunder</h4>{" "}
      </p>
      <div className="row clearfix">
      {content.konflik_sekunder.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                        <div className="content">
                          <CardConflict
                            title={item.title}
                            subtitle={"Kekuatan : " + item.kekuatan}
                            text={item.dukungan}
                          />
                        </div>
                      </div>
                    );
                })}
       
      </div>
      <p>
        <h4>Tersier</h4>{" "}
      </p>
      <div className="row clearfix">
    
      {content.konflik_tersier.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                        <div className="content">
                          <CardConflict
                            title={item.title}
                            subtitle={"Kekuatan : " + item.kekuatan}
                            text={item.dukungan}
                          />
                        </div>
                      </div>
                    );
                })}
         </div>
      </div>
    </Conflict>
  );
}

export default ConflictProfile;
