import React from "react";
import styled from "styled-components";

import { RectangleCopy13jpgImage } from "../images";

const Card = styled.div`
  background:#D0D0B1;
  border-radius:10px;

  @media screen and (max-width: 420px) {
    
    border-radius: 10px;
    
  }
  p {
    font-size : 12px;
    margin-right: 2px;
  }
  .card{
    padding:10px;
  }
  ul{
    margin-left:-20px;
  }
`;

function CardChar(props) {
  const { title, text } = props;
  return (
    <Card className="card text-dark">
      <div className="content">
        <div className="card">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p className="title mt-2">
                <strong>{title.toUpperCase()}</strong>
              </p>
              <p dangerouslySetInnerHTML={{__html:text}}></p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CardChar;
