import React from "react";
import CardElement from "../CardElement";
import styled from "styled-components";
import {
  diagramImage,
  shio4Image,
  BackgroundLayerCopycopypngImage,
  shio2Image,
  sio3Image,
  sioImage,
  BackgroundLayerCopy1Image,
  BackgroundLayerCopydImage,
  BackgroundLayerCopycopy1pngImage,
} from "../images";

const Elm = styled.section`
h4 {
  margin-top:-40px;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}

.jumbotron {
  background-color: #F8F8F5;
}
@media screen and (max-width: 420px) {
  
  p.content{
    flex-direction:column !important;
  }
}
`;

function Elements(props) {
  const {content} = props;
  return (
    <Elm className="container">
      <div className="jumbotron">
      <div className="d-flex justify-content-center">
        <img
          src={diagramImage.url}
          alt={diagramImage.alt}
          className="bg-white mb-4 px-5 py-5"
          width={window.innerWidth > 700 ? 500 : '100%'}
          height="auto"
          style={{margin:'0 auto'}}
        />
      </div>
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-ALCHEMIST.png"}
        alt={"alchemist"}
        title={"Alchemist"}
        text={`<p>Penyempurnaan yang baik, penyelesaian proses, menemukan cara yang lebih baik dab cerdas, meningkatkan efesiensi dan efektivitas produk/sistem yang ada.</p>
        <p>Seseorang yang memilki kemampuan beradaptasi dengan menempatkan diri sesuai dengạn dimana ia berada. mampu untuk tetap merendah dan secara frontal berusaha untuk mengejar setiap peluang yang ada.</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-WIZARD.png"}
        alt={"Wizard"}
        title={"Wizard"}
        text={`<p>Konseptor yang baik, penggagas hal baru, kreatif, inovatif, pencipta ide, pemikir out-of-the-box, spontan, tidak teratur, impulsif, baik untuk memulai sesuatu yang baru.</p>
        <p>Seseorang yang mampu berinovasi secara berkelanjutan, seringkali kurang bisa dipahami oleh banyak orang disekitarnya, namun sanggup berpikir out-of-the-box, dalam menghasilkan konsep-konsep baru.</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-BARD.png"}
        alt={"Bard"}
        title={"Bard"}
        text={` <p>Promotor yang baik, pemasar yang berbakat, merek pribadi yang kuat, bergerak cepat, butuh perhatian, bersinar, mampu menginspiraşi orang lain, individu yang kokoh.</p>
        <p>Seseorang yang mampu memberi energi kepada orang-orang di sekelilingnya, mendukung dan menyebarkan semangat secara konsisten, sehingga mampu menyampaikan segala hal dengan penerimaan yang relatif mudah.</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-KNIGHT.png"}
        alt={"Priest"}
        title={"Priest"}
        text={` <p>Penjaga yang baik, dapat diandalkan, sabar, berhati-hati, berbasis fakta, safety-player, terstruktur, unggul dengan analisis informasi, cerdas dalam mengelola risiko.</p>
        <p>Seseorang yang mampu menegakkan aturan secara tegas, disegani oleh orang- orang dibawahnya, dan ditakuti oleh siapapun yang melakukan pelanggaran aturan, dengan keteguhan tinggi sehingga mampu dijadikan sebagai tauladan (role-model).</p>`}
       
        
      />
      
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-ARBITER.png"}
        alt={'ARBITER'}
        title={"ARBITER"}
        text={` <p>Penengah yang baik, pembawa damai, representatif bijak, melihat alternatif sebagai manfaat, penghindar konflik, berinvestasi pada orang untuk manfaat jangka panjang.</p>
        <p>Seseorang yang tegas serta mampu menciptakan kemungkinan baru, walaupun sebelumnya dirasakan tidak ada jalan lain, dimana angin biasanya memang tidak terlibat namun bisa dirasakan kehadirannya, mengisi apapun dan dimanapun.</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-MERCHANT.png"}
        alt={"Merchant"}
        title={"Merchant"}
        text={` <p>Negosiator ulung, pedagang yang handal, pencari peluang, pengguna waktu yang efektif dan efisien, fleksibel, bekerja dalam pola/parameter tertentu, berdasarkan data.</p>
        <p>Seseorang yang mampu memanfaatkan kekuatan dari wawasan yg luas, yang menampung apapun dari segala penjuru, mencapai keberhasilan dengan menerima berbagai hal, dan memanfaatkannva untuk keberhasilan yang lebih besar.&nbsp;</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-PRIEST.png"}
        alt={"Knight"}
        title={"Knight"}
         text={`<p>Influencer yang baik, pemimpin yang karismatik, manajer pelaksana, penyelenggara, administrator, pendukung, baik dalam menciptakan dan mengelola hubungan, baik dalam manajemen sumber daya manusia, fleksibel.</p>
        <p>Seorang yang selalu mampu memberi semangat pada orang-orang disekitarnya, dimana api mampu menerangi kegelapan dan menyambar kemanapun, sehingga ia bisa mempengaruhi lebih banyak orang untuk sejalan seiring berjalannya waktu.</p>`}
      />
      <CardElement
        displayCircle="none"
        src={"https://shiokaya.nos.jkt-1.neo.id/S-MARSHAL.png"}
        alt={"Marshal"}
        title={"Marshal"}
        text={`<p>Penganalisa yang baik, prediktif, lebih efektif di belakang layar, terencana, berkomitmen, pengatur yang handal, berkemauan keras, regulator yang baik.</p>
        <p>Seseorang yang kokoh dan bisa diandalkan, terbuka untuk berbagai peluang yang lewat atau dilewatinya, dan mampu memanfaatkan apapun yang saat ini dimilikinya, termasuk memanfaatkan kekuatan waktu dan prioritas-prioritas yang tersusun dengan baik.</p>`}
      />
      </div>
    </Elm>
  );
}

export default Elements;
