import React from "react";
import {Grid} from "@material-ui/core";

export default function Header(props){
    return (
        <header
            style={{
           
              background: "#ffffff",
              borderBottom: "2px solid #000000",
              position: "fixed",
              width: '100%',
              zIndex: 3,
              top: 0
            }}
          >
              <Grid container>
                  <Grid item xs="12" md="12" style={{textAlign: window.innerWidth < 500 ? 'center' : 'center'}}>
                        <img src={require('../../assets/logo-shiokaya.png')} style={{height:50}}/>
                  </Grid>
              </Grid>
           
          </header>
    );
}