import React from "react";
import styled from "styled-components";

const Prof = styled.div`
  margin-bottom: 5px;
  padding-bottom: 10px;
  p {
    margin-top: 20px;
    margin-left: 20px;
    text-align: left;
    padding-bottom: 1px;
  }
  .prime{
    font-weight:700;
  }
  @media screen and (max-width: 420px) {
      p {
        margin-bottom: 1px;
        margin-top: 12px;
     
        font-size: 100%;
      }
      .prime{
        font-weight:700;
      }
  }
  `;
function CardConflict(props) {
  const { title, subtitle, text } = props;
  return (
    <Prof className="card bg-white text-dark">
      <p class="prime">
        {title}
      </p>
      <p>{subtitle}</p>
      <span dangerouslySetInnerHTML={{__html:text}}></span>
    </Prof>
  );
}

export default CardConflict;
