import React from "react";
import {
  Grid,
  CircularProgress,
  Slider,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import loginAsGuestAction from "../../redux/actions/loginAsGuestAction";

import Header from "./Header";
import Footer from "../Footer";

import CallAction from "../../redux/actions/CallAction";

const _ = require("lodash");
const qs = require('qs');
const defaults = [
  {
    subject: "Wizard",
    score: 0,
    fullMark: 150,
    name: "creator"
  },
  {
    subject: "Bard",
    score: 0,
    fullMark: 150,
    name: "star"
  },
  {
    subject: "Priest",
    score: 0,
    fullMark: 150,
    name: "supporter"
  },
  {
    subject: "Knight",
    score: 0,
    fullMark: 150,
    name: "dealmaker"
  },
  {
    subject: "Merchant",
    score: 0,
    fullMark: 150,
    name: "trader"
  },
  {
    subject: "Oracle",
    score: 0,
    fullMark: 150,
    name: "accumulator"
  },
  {
    subject: "Warlock",
    score: 0,
    fullMark: 150,
    name: "lord"
  },
  {
    subject: "Alchemist",
    score: 0,
    fullMark: 150,
    name: "mechanic"
  }
];

const getCombo = data => {
  if (typeof data.left === "undefined") data.left = 0;
  if (typeof data.right === "undefined") data.right = 0;
  if (typeof data.up === "undefined") data.up = 0;
  if (typeof data.down === "undefined") data.down = 0;
  if (typeof data.upperleft === "undefined") data.upperleft = 0;
  if (typeof data.upperright === "undefined") data.upperright = 0;
  if (typeof data.lowerleft === "undefined") data.lowerleft = 0;
  if (typeof data.lowerright === "undefined") data.lowerright = 0;

  if (data.left > data.right && data.down < data.up) {
    return [7];
  } else if (data.left > data.right && data.down > data.up) {
    return [5];
  } else if (data.left < data.right && data.down < data.up) {
    return [1];
  } else if (data.left < data.right && data.down > data.up) {
    return [3];
  } else if (data.left === data.right && data.down < data.up) {
    return [0];
  } else if (data.left === data.right && data.down > data.up) {
    return [4];
  } else if (data.left < data.right && data.down === data.up) {
    return [2];
  } else if (data.left > data.right && data.down === data.up) {
    return [6];
  } else {
    //return [0, 0, 0];
    if(data.upperleft > data.lowerright && data.lowerleft > data.upperright){
      return [6];
    }
    if(data.upperleft < data.lowerright && data.lowerleft > data.upperright){
      return [4];
    }
    if(data.upperleft > data.lowerright && data.lowerleft < data.upperright){
      return [0];
    }
    if(data.upperleft < data.lowerright && data.lowerleft < data.upperright){
      return [2];
    }
  }

  /*
  if (data.left > data.right && data.down < data.up) {
    return [0, 6, 7];
  } else if (data.left > data.right && data.down > data.up) {
    return [4, 5, 6];
  } else if (data.left < data.right && data.down < data.up) {
    return [0, 1, 2];
  } else if (data.left < data.right && data.down > data.up) {
    return [2, 3, 4];
  } else if (data.left === data.right && data.down < data.up) {
    return [7, 0, 1];
  } else if (data.left === data.right && data.down > data.up) {
    return [3, 4, 5];
  } else if (data.left < data.right && data.down === data.up) {
    return [1, 2, 3];
  } else if (data.left > data.right && data.down === data.up) {
    return [5, 6, 7];
  } else {
    return [0, 0, 0];
  }
  */

};
class TestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      clusters: [],
      finished: false,
      questions: [],
      progresses: [],
      result_data: [],
      chartkey: Math.random() * 99999,
      max: 0,
      start: false,
      pretests: [],
      show_pretest_result: false,
      name: null,
      email: null,
      phone: null,
      contentButton: null
    };
    this.handlePretests = this.handlePretests.bind(this);
    this.handleSubmits = this.handleSubmits.bind(this);
    this.calculate = this.calculate.bind(this);
    this.renderPretestResult = this.renderPretestResult.bind(this);
    this.savingEmail = this.savingEmail.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
  }

  componentDidMount() {
    const { location, call_action, callAction } = this.props;
    let token = localStorage.getItem("token");
    let chunk = location.search.split("?");
    let qparams = (chunk.length > 0) ? qs.parse(chunk[1]) : null;
    if (typeof qparams.promo !== 'undefined') {
      localStorage.setItem("promo", qparams.promo);
    }

    this.props.loginAsGuest();
    callAction(call_action, "home", {
      endpoint: "/page/content/home",
      scenario: 'get'
    })

  }
  componentDidUpdate(_props, _states) {
    const { guestLogin, call_action, callAction } = this.props;

    if (_props.guestLogin.payload !== guestLogin.payload) {
      this.setState({
        fetching: guestLogin.fetching
      });
      if (typeof guestLogin.payload === "undefined") return;
      if (guestLogin.payload !== null) {
        localStorage.setItem("token", guestLogin.payload.access_token);
        localStorage.setItem("session_id", guestLogin.payload.user.session_id);
        localStorage.setItem("name", guestLogin.payload.user.name);
        callAction(call_action, "pretests", {
          endpoint: "/pretests",
          data: {
            per_page: 20,
            orderBy: {
              field: "id"
            },
            orderDirection: "asc"
          }
        });
      }
    }




    if (_props.call_action.pretests !== call_action.pretests) {
      this.handlePretests(call_action.pretests);
    }
    if (_props.call_action.pretest_submit !== call_action.pretest_submit) {
      this.handleSubmits(call_action.pretest_submit);
    }
    //email_submit
    if (_props.call_action.email_submit !== call_action.email_submit) {
      this.handleEmailSubmit(call_action.email_submit);
    }
    if (call_action.fetching !== _props.call_action.fetching) {
      this.setState({
        fetching: call_action.fetching
      });
    }
  }
  handleEmailSubmit(submit) {
    if (typeof submit === 'undefined') return;
    if (submit === null) return;
    if (typeof submit.status === 'undefined') return;


    this.props.history.push("/quiz", {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    });

    /*
    this.props.history.push("/quiz",{
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    });*/
  }
  getValues(prop) {
    if (typeof prop === "undefined") return [];
    if (prop === null) return [];
    if (typeof prop.data === "undefined") return [];
    if (prop.data === null) return [];
    return prop.data;
  }
  handlePretests(pretests) {
    if (typeof pretests === 'undefined') return;
    if (pretests === null) return;
    if (typeof pretests.data === "undefined") return;
    if (pretests.data === null) return;
    this.setState({
      contentButton: pretests.content
    })
  }
  handleSubmits(submits) {

  }
  savingEmail() {
    const { callAction, call_action } = this.props;
    const { name, email, phone } = this.state;
    const session_id = localStorage.getItem("session_id");

    callAction(call_action, "email_submit", {
      endpoint: "/email/submit",
      data: {
        name,
        email,
        phone,
        session_id
      },
      scenario: "post"
    });
  }
  calculate() {
    const { callAction, call_action } = this.props;
    let progresses = this.state.progresses;
    let score = {};
    progresses.map(p => {
      if (p === null) return;
      if (typeof score[p] === "undefined") score[p] = 0;
      score[p]++;
    });

    let combo = getCombo(score);
    console.log(progresses,score, combo);
    
    callAction(call_action, "pretest_submit", {
      endpoint: "/pretests/submit",
      data: {
        //ids: combo
        ids: combo
      },
      scenario: "post"
    });
    
  }
  renderPretestResult() {
    const { classes, call_action, callAction } = this.props;
    const { pretest_submit } = call_action;
    const { contentButton, page, progresses, fetching, show_pretest_result } = this.state;

    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
            paddingBottom: 80,
            maxWidth: 500,
            margin: "0 auto"
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {fetching ? (
                <CircularProgress />
              ) : (
                  <div>
                    <p style={{ fontSize: "120%", textAlign: "center" }}>
                      Selamat! Dibawah ini adalah 3 kemungkinan terbesar dari total 8 profil Shio Kaya Anda. 3 kecenderungan cara alamiah Anda, untuk bisa mencapai keberhasilan dengan waktu dan tenaga yang lebih efektif dan efisien.
                  </p>

                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: 30
                      }}
                    >
                      <Grid container spacing={3}>
                        {pretest_submit.data.map((item, index) => {
                          return (
                            <Grid item xs={12} md={4}>
                              <div style={{ height: 220, overflow: "hidden" }}>
                                <img
                                  src={item.photo_url}
                                  style={{ width: 150 }}
                                />
                              </div>
                              <div>
                                <h4>{item.title}</h4>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <div style={{ textAlign: "left" }}>
                        {pretest_submit.data.map((item, index) => {
                          return (
                            <div>
                              <h3>{item.title}</h3>

                              {index === 0 ? (<p dangerouslySetInnerHTML={{ __html: item.brief1 }} />) : ''}
                              {index === 1 ? (<p dangerouslySetInnerHTML={{ __html: item.brief2 }} />) : ''}
                              {index === 2 ? (<p dangerouslySetInnerHTML={{ __html: item.brief3 }} />) : ''}
                            </div>
                          )
                        })}

                      </div>

                    </div>
                    {contentButton !== null ? (
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: 30
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            document.location = contentButton.button_url
                          }}
                        >
                          {contentButton.button_label}
                        </Button>
                      </div>
                    ) : null}

                  </div>
                )}
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
  render() {
    const { classes, call_action, callAction } = this.props;
    const { pretests } = call_action;
    const { contentButton, page, progresses, fetching, show_pretest_result } = this.state;

    if (show_pretest_result) {
      return this.renderPretestResult();
    }

    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
            paddingBottom: 80,
            maxWidth: 500,
            margin: "0 auto"
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <p style={{ fontSize: "120%" }}>
                Mohon kerjakan test dibawah ini, dengan memilih jawaban, sesuai
                dengan petunjuk yang diberikan pada tiap nomor. Baca baik-baik,
                dan pastikan anda mengerti apa yang akan anda pilih. Tiap
                jawaban anda, menentukan ketepatan hasil yang akan berguna bagi
                anda sendiri.
              </p>

              {!this.state.fetching ? (
                this.getValues(pretests).map((q, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        padding: "8px 10px 20px 10px",
                        backgroundColor: "#f1f2f6",
                        borderRadius: 4,
                        marginBottom: 10
                      }}
                    >
                      <div style={{ fontSize: "120%" }}>
                        <span style={{ fontWeight: "bold" }}>{index + 1}.</span>{" "}
                        {q.question}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <RadioGroup
                          aria-label="quiz"
                          name="quiz"
                          value={progresses[q.id]}
                          onChange={event => {
                            let v = progresses;
                            v[q.id] = event.target.value;
                            this.setState({
                              progresses: v
                            });
                          }}
                        >
                          <FormControlLabel
                            value={q.answer_slot1}
                            control={<Radio />}
                            label={q.answer1}
                          />
                          <hr
                            size={1}
                            style={{
                              border: 0,
                              width: "100%",
                              height: 1,
                              backgroundColor: "#ccc"
                            }}
                          />
                          <FormControlLabel
                            value={q.answer_slot2}
                            control={<Radio />}
                            label={q.answer2}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  );
                })
              ) : (
                  <CircularProgress />
                )}

              <div
                style={{
                  textAlign: "center",
                  paddingTop: 30
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.calculate();
                    this.setState({
                      fetching: true,
                      show_pretest_result: true
                    });
                  }}
                >
                  LIHAT HASILNYA
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { guestLogin, call_action } = state;
  return {
    guestLogin,
    call_action
  };
};

const mapDispatchToProps = dispatch => ({
  loginAsGuest: data => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(TestContainer));
