import Api from "../../Services/Api"; 

const api = new Api();

export default (data) => dispatch => {
    
    dispatch({
        type: 'CONFIRM',
        data
    })
    
    api.call({
        endpoint:'/confirm',
        data,
        scenario:"post"
    },true).then((response)=>{
        
        if(typeof response.status !== 'undefined'){
            dispatch({
                type:"CONFIRM_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"CONFIRM_ERROR",
                message: response.error
            })
        }
        
    })
  
}