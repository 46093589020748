import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { WhatsApp, Email,Instagram } from "@material-ui/icons";

export default function Footer(props) {
  return (
    <div
      style={{
        marginTop: 60,
        background: "#000000",
        paddingBottom:window.innerWidth > 700 ? 0 : 60
      }}
    >
      <div
        style={{
          maxWidth: window.innerWidth > 700 ? 1140 : "100%",
          margin: "0 auto",
          padding: 30,
          paddingTop: 34,
         
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div
              style={{
                fontSize: "100%",
                lineHeight: "150%",
                padding: 10,
                color: "white",
              }}
            >
              <h4 style={{ margin: 0 }}>ShioKaya</h4>
              <p>
              8 Profil Alamiah Dalam Mencapai Keberhasilan. Kekuatan dasar dalam mencapai kekayaan dengan cara yang paling ringan (effortless)
              </p>
              <h5>Follow:</h5>
              <div style={{display:'flex',flexDirection:'row'}}>
              <Instagram style={{marginRight:10}}/>
              <a href="https://www.instagram.com/shiokayaid" target="_blank" style={{
                color: 'white',
                fontWeight: 700,
                fontSize: '150%'
              }}>shiokayaid</a>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div
              style={{
                fontSize: "120%",
                lineHeight: "150%",
                padding: 10,
                color: "white",
              }}
            >
              <h3>Butuh Bantuan?</h3>
              <p>
                <WhatsApp style={{ marginRight: 5 }} />{" "}
                <span
                  style={{
                    top: -4,
                    position: "relative",
                  }}
                >
                  0882 9722 0776
                </span>
                <br />
                <Email style={{ marginRight: 10 }} />
                <span
                  style={{
                    top: -4,
                    position: "relative",
                  }}
                >
                  support@shiokaya.com
                </span>
              </p>

              {/*}
              <ul
                style={{
                  listStyle: "none",
                  padding: 0
                }}
              >
                <li>Tentang Kami</li>
                <li>Produk &amp; Layanan</li>
                <li>Member Area</li>
                <li>Kebijakan Privasi</li>
                <li>Kontak</li>
              </ul>
              {*/}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
