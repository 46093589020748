import React from "react";
import CardStarValue from "../CardStarValue";
import styled from "styled-components";

const ProfIns = styled.section`
  .jumbotron {
    background-color: #f8f8f5;
  }

  h4 {
    margin-top: 0;
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  p {
   
    font-family: "Poppins";
  }

  span {
   
    font-family: "Poppins";
  }

  @media screen and (max-width: 420px) {
    h4 {
      margin-top:0;
    }
  }
`;

function ProfileInstinct(props) {
  const { content } = props;
  return (
    <ProfIns className="container">
      <div className="jumbotron">
        <h4 className="text-center">
          Naluri alami Anda dalam penciptaan kekayaan dan kesuksesan
        </h4>
        <span
          dangerouslySetInnerHTML={{
            __html: content.naluri_copy,
          }}
        />
        <CardStarValue
          starValue={content.nilai_naluri[0]}
          title="Influencing"
          subtitle={content.naluri.influencing}
        />
        <CardStarValue
          starValue={content.nilai_naluri[1]}
          title="Arranging"
          subtitle={content.naluri.arranging}
        />
        <CardStarValue
          starValue={content.nilai_naluri[2]}
          title="Accumulating"
          subtitle={content.naluri.accumulating}
        />
        <CardStarValue
          starValue={content.nilai_naluri[3]}
          title="Integrating"
          subtitle={content.naluri.integrating}
        />
      </div>
    </ProfIns>
  );
}

export default ProfileInstinct;
