import React from "react";
import CardStarValue from "../CardStarValue";
import styled from "styled-components";

const ProfVal = styled.section`
.jumbotron {
  background-color: #F8F8F5;
}

h4 {
  margin-top:0;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}
p {
  
  font-family: "Poppins";
}

span {
  
  font-family: "Poppins";
}

@media screen and (max-width: 420px) {
  h4 {
    margin-top:0;
  }
}
`;

function ProfileValues(props) {
  const {content} = props;
  return (
    <ProfVal className="container">
      <div className="jumbotron">
      <h4 className="title text-center">
        Nilai (value) untuk menggunakan kekuatan diri Anda
      </h4>
      <span
                    
                      dangerouslySetInnerHTML={{
                        __html: content.nilai,
                      }}
                    />
      <CardStarValue
        starValue={content.nilai_kekuatan[0]}
        title="Inovasi"
        subtitle={content.kekuatan.inovasi}
      />
      <CardStarValue
        starValue={content.nilai_kekuatan[1]}
        title="Komunikasi"
        subtitle={content.kekuatan.komunikasi}
      />
      <CardStarValue
        starValue={content.nilai_kekuatan[2]}
        title="Waktu"
        subtitle={content.kekuatan.waktu}
      />
      </div>
    </ProfVal>
  );
}

export default ProfileValues;
