const styles = theme => ({
    bullet:{
        background:'#333',
        padding:2,
        width: 1,
        height: 2,
        
        margin: '0 auto',
        marginBottom: 8,
        marginTop: -18,
    }
});
export default styles;