import React, { useState } from "react";
import { Grid, CircularProgress, Button, TextField } from "@material-ui/core";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Legend
} from "recharts";


const TextLabel = props => {
  const { name, text } = props;
  return (
    <div>
      <h4
        style={{
          fontSize: "120%",
          margin: 0,
          display: "inline-block",
          marginRight: 10
        }}
      >
        {name}
      </h4>
      <span style={{ fontSize: "120%" }}>{text}</span>
    </div>
  );
};
export default function QuizResult(props) {
  const { data, content, isLogin, old, onSubmit,history } = props;
  const [name, setName] = useState(typeof old !== "undefined" ? old.name : "");
  const [email, setEmail] = useState(
    typeof old !== "undefined" ? old.email : ""
  );
  const [phone, setPhone] = useState(
    typeof old !== "undefined" ? old.phone : ""
  );
  const [confirm, setConfirm] = useState(false);
  console.log("content", content);
  if (data === null)
    return (
      <div>
        <CircularProgress />
      </div>
    );
  if (typeof content === "undefined")
    return (
      <div>
        <CircularProgress />
      </div>
    );
  if (content === null)
    return (
      <div>
        <CircularProgress />
      </div>
    );
  return (
    <div style={{ marginTop: 100 }}>
      {content !== null ? (
        <Grid
          container
          style={{
            maxWidth: 800,
            marginTop: 60,
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Grid item xs={12} md={12}>
            <div style={{ margin: 30, textAlign: "center", fontSize: "120%" }}>
              Profil akurat potensi model <strong>keberhasilan</strong> anda
              adalah:
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              style={{
                textAlign: window.innerWidth < 600 ? "center" : "left",
                marginTop: 40,
                marginBottom: 60,
                padding: 30
              }}
            >
              <div
                style={{
                  margin: "0 auto",
                  width: 300,
                  textAlign: window.innerWidth < 600 ? "center" : "left"
                }}
              >
                <TextLabel name={"Elemen"} text={content.nama + "(" + content.simbol + ")"} />

              </div>

              <img
                src={content.logo_url}
                style={{
                  width: 150
                }}
              />
              <div
                style={{
                  margin: "0 auto",
                  width: 300,
                  textAlign: window.innerWidth < 600 ? "center" : "left"
                }}
              >
                <TextLabel name={"Warna"} text={content.warna} />
                <div
                  style={{
                    marginTop: 5,
                    width: 120,
                    height: 30,
                    backgroundColor: content.color,
                    marginBottom: 20,

                  }}
                ></div>
                <div>
                  <p>{content.fungsi}</p>
                </div>

              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "center" }}>
              <h1 style={{ margin: 0 }}>{content.title.toUpperCase()}</h1>
              <h3 style={{ margin: 0 }}>({content.tagline})</h3>
              <img
                src={content.photo_url}
                style={{
                  width: window.innerWidth > 600 ? "50%" : 300
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div
              style={{
                marginTop: window.innerWidth > 600 ? 30 : 30,
                padding: "10px 0px 0px 10px"
              }}
            >
              <div>
                <p dangerouslySetInnerHTML={{ __html: content.karakter }} />
                <p dangerouslySetInnerHTML={{ __html: content.summary }} />

              </div>

            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              style={{
                marginTop: 10,
                width: "100%",
                height: 30,
                backgroundColor: content.color,
                marginBottom: 5,
                borderBottom: "10px #000000 solid"
              }}
            ></div>
          </Grid>
        </Grid>
      ) : null}
      <div
        style={{
          padding: 30,
          maxWidth: 700,
          margin: "0 auto",
          textAlign:"center",
        }}
      >
        <Button variant={'contained'} color={'primary'} onClick={()=>{
         onSubmit();
        }}>LANJUT</Button>
      </div>
       
    </div>
  );
}
