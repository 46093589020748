import Api from "../../Services/Api"; 

const api = new Api();

export default (data) => dispatch => {
    console.log("GET QUESTIONS");
    dispatch({
        type: 'QUESTIONS',
        data
    })
    
    api.questions(data).then((response)=>{
        
        if(typeof response.status !== 'undefined'){
            dispatch({
                type:"QUESTIONS_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"QUESTIONS_ERROR",
                message: response.error
            })
        }
        
    })
  
}