import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import routes from './routes';
import './App.css';


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});
function App(props) {
  
  let currentPath = window.location.pathname;
  let isAppBarShow = true;
  if (currentPath === '/login' ) isAppBarShow = false;

  return (
    <Router>
     
      {routes.map((route, index) => (
        // Render more <Route>s with the same paths as
        // above, but different components this time.
        <ThemeProvider  key={index} theme={theme}>
             <Route
             
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
        </ThemeProvider>
       
      ))}

    </Router>
  );
}

export default connect()(App);
