import React, { useState } from "react";
import { Grid, CircularProgress, Button, TextField } from "@material-ui/core";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Legend
} from "recharts";

export default function InvoiceAuto(props) {
  const { data, confirm_url,toConfirm, history,onBayar } = props;

  return (
    <div style={{ marginTop: 100 }}>
      <Grid
        container
        style={{
          maxWidth: 800,
          marginTop: 60,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Grid item xs={12} md={12}>
          <div style={{ margin: 30, textAlign: "left", fontSize: "120%" }}>
            <h1 id="-akses-halaman-detail-premium-anda-">
              HALAMAN DETAIL PREMIUM ANDA
            </h1>
            
            <p>
              Untuk 10 lembar detil informasi profil anda, yang anda bisa
              manfaatkan untuk kemajuan diri, pekerjaan dan bisnis anda.
            </p>
            <h2 id="-apa-saja-yang-akan-saya-peroleh-">
              <em>Apa saja yang akan saya peroleh ?</em>
            </h2>
            <ol>
              <li>
                Anda akan memperoleh hasil laporan detail tentang diri anda.
              </li>
              <li>
                Anda akan memperoleh laporan lengkap dalam bentuk infografis
                yang mudah dimengerti.
              </li>
              <li>
                Anda akan memperoleh akun khusus untuk anda memperoleh informasi
                tambahan spesifik profil anda (berdasarkan kondisi dunia bisnis
                terkini).
              </li>
            </ol>
           
            
          </div>
          <div style={{textAlign:'center'}}>
            <Button variant={"contained"} color={'primary'} onClick={()=>{
              onBayar();
            }}>YA, SAYA MAU</Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
