import React from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import loginAsGuestAction from "../redux/actions/loginAsGuestAction";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Heading from "./parts/Header";
import Navbar from "./parts/Navbar";

import Testimonials from "./parts/Testimonials";
import Trust from "./parts/Trust";
import Test from "./parts/Test";

import YouTube from 'react-youtube';

import {backgorundColor} from "./parts/images";
import CallAction from "../redux/actions/CallAction";
import Footer from "./Footer";

const qs = require('qs');

class LandingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: "",
      page: 1,
      content: null,
      buylink:'/buy',
    };
    this.initHomeContent = this.initHomeContent.bind(this);

  }
  componentDidMount() {
    const { location, call_action, callAction } = this.props;
    let token = localStorage.getItem("token");
    let chunk = location.search.split("?");
    let qparams = (chunk.length > 0) ? qs.parse(chunk[1]) : null;

    localStorage.setItem(
      "affiliate_id",
      qparams.affiliate_id ? qparams.affiliate_id : 0
    );

    if (typeof qparams.promo !== 'undefined') {
      localStorage.setItem("promo", qparams.promo);
    }
   
    this.props.loginAsGuest();

    callAction(call_action, "home", {
      endpoint: "/page/content/home",
      scenario: 'get'
    });
    let aid = localStorage.getItem("affiliate_id") ? parseInt(localStorage.getItem("affiliate_id")) : 0;
    this.setState({
      buylink: aid > 0 ? '/buy?affiliate_id=' + localStorage.getItem("affiliate_id") : '/buy'
    })

    //production disini
    //document.location="http://shiokaya.id";
    //localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { guestLogin, call_action } = this.props;
    if (_props.guestLogin.payload !== guestLogin.payload) {
      this.setState({
        fetching: guestLogin.fetching
      });
      if (typeof guestLogin.payload === "undefined") return;
      if (guestLogin.payload !== null) {
        localStorage.setItem("token", guestLogin.payload.access_token);
        localStorage.setItem("session_id", guestLogin.payload.user.session_id);
        localStorage.setItem("name", guestLogin.payload.user.name);
        //this.props.history.push("/quiz");
      }
    }
    
    if (call_action.home !== _props.call_action.home) {
      this.initHomeContent(call_action.home);
    }
  }
  initHomeContent(payload) {
    if (typeof payload === 'undefined') return;
    if (payload === null) return;
    this.setState({
      content: payload
    })
  }
  render() {
    const { classes, history } = this.props;
    const { fetching, page, content,buylink } = this.state;
    if (content === null) return "";
    return (
        <>
             <Navbar loginProp={{ isLogin: false }} />
                <div style={{
                    backgroundImage: `url(${backgorundColor.url})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <Heading />
                </div>
              
                {/*}<Testimonials />
              <Trust />{*/}
                <Test buylink={buylink}/>
                <Footer/>
        </>
    );
  }
}

const mapStateToProps = state => {
  const { guestLogin, call_action } = state;
  return {
    guestLogin,
    call_action
  };
};

const mapDispatchToProps = dispatch => ({
  loginAsGuest: data => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LandingContainer));
