import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import QuizResult from "./QuizResult";
import Invoice from "./InvoiceAuto";
import Bayar from "./Bayar";

import QuestionAction from "../../redux/actions/QuestionAction";
import ResultAction from "../../redux/actions/ResultAction";
import GetResultAction from "../../redux/actions/GetResultAction";
import Header from "./Header";
import CallAction from "../../redux/actions/CallAction";
import Footer from "../Footer";
import Navbar from "../parts/Navbar";
import HeaderFourtyEigthQuest from "../parts/HeaderFourtyEigthQuest";
import FormFourtyEight from "../parts/FormFourtyEight";

const _ = require("lodash");

const defaults = [
  {
    subject: "Wizard",
    score: 0,
    fullMark: 150,
    name: "creator",
  },
  {
    subject: "Bard",
    score: 0,
    fullMark: 150,
    name: "star",
  },
  {
    subject: "Priest",
    score: 0,
    fullMark: 150,
    name: "supporter",
  },
  {
    subject: "Knight",
    score: 0,
    fullMark: 150,
    name: "dealmaker",
  },
  {
    subject: "Merchant",
    score: 0,
    fullMark: 150,
    name: "trader",
  },
  {
    subject: "Oracle",
    score: 0,
    fullMark: 150,
    name: "accumulator",
  },
  {
    subject: "Warlock",
    score: 0,
    fullMark: 150,
    name: "lord",
  },
  {
    subject: "Alchemist",
    score: 0,
    fullMark: 150,
    name: "mechanic",
  },
];


class QuizContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      clusters: [],
      finished: false,
      questions: [],
      progresses: [],
      result_data: [],
      chartkey: Math.random() * 99999,
      max: 0,
      start: false,
      fetching: false,
      payment: false,
      bayar: false,
      pay_url: "",
      price: 200000,
      paid: false,
    };

    this.createCluster = this.createCluster.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.saveProgresses = this.saveProgresses.bind(this);
    this.loadQuestions = this.loadQuestions.bind(this);
    this.initClusters = this.initClusters.bind(this);
    this.populateResult = this.populateResult.bind(this);
    this.checkExistingResult = this.checkExistingResult.bind(this);
    this.handleResult = this.handleResult.bind(this);
    this.validateSessionId = this.validateSessionId.bind(this);
    this.handleValidateSession = this.handleValidateSession.bind(this);
    this.register = this.register.bind(this);
    this.handleRegistration = this.handleRegistration.bind(this);

    this.bayar = this.bayar.bind(this);
    this.handleBayar = this.handleBayar.bind(this);
  }

  componentDidMount() {
    // this.checkExistingResult();
    // this.initQuiz();
    //only user who has input the email and phone number can enter the quiz
    this.validateSessionId();
  }
  componentDidUpdate(_props, _states) {
    const {
      questions,
      resultAction,
      result,
      get_result,
      call_action,
      callAction,
    } = this.props;
    const { finished, page, clusters, start } = this.state;
    if (_props.questions.payload !== questions.payload) {
      if (typeof questions.payload !== "undefined") {
        if (questions.payload !== null)
          this.loadQuestions(questions.payload.data);
      }
    }
    if (_props.get_result !== get_result) {
      if (typeof result !== "undefined") {
        if (
          typeof get_result.payload !== "undefined" &&
          get_result.payload !== null
        ) {
          if (get_result.payload.status === 1) {
            let rs = get_result.payload.result;
            //this.populateResult(rs);
            this.props.history.push("/premium");
          }
        }
      }
    }
    if (this.state.questions !== _states.questions) {
      if (this.state.questions !== null) this.initClusters();
    }

    if (
      page !== _states.page &&
      _states.clusters.length > 0 &&
      _states.finished === false
    ) {
      if (parseInt(page) >= clusters.length) {
        this.setState({
          finished: true,
        });
      }
    }

    if (finished !== _states.finished && finished === true) {
      if (get_result.payload === null) {
        console.log("FINISHED");
       /* resultAction({
          session_id: localStorage.getItem("session_id"),
          questions: this.state.questions,
          progresses: this.state.progresses,
        });*/
      }
    }

    if (_props.get_result !== get_result) {
      this.handleGetResult(get_result);
    }
    if (_props.result !== result) {
      this.handleResult();
    }

    if (_states.start !== this.state.start) {
      if (this.state.start === true) this.initQuiz();
    }

    if (call_action.validate_session !== _props.call_action.validate_session) {
      this.handleValidateSession(call_action.validate_session);
    }

    if (call_action.register_premium !== _props.call_action.register_premium) {
      this.handleRegistration(call_action.register_premium);
    }
    if (call_action.bayar !== _props.call_action.bayar) {
      this.handleBayar(call_action.bayar);
    }
  }
  validateSessionId() {
    const { callAction, call_action } = this.props;
    callAction(call_action, "validate_session", {
      endpoint: "/me",
      
    });
  }
  handleValidateSession(payload) {
    const { history } = this.props;
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (payload.status === 0) history.push("/login");
    if (payload.status === 1) {
      
      if(payload.flag_quiz === false){
        //localStorage.setItem("session_id", payload.session_id);
        this.initQuiz();
      }else{
        document.location="/premium";
      }
      
    }
  }
  handleRegistration(payload) {
    const { history } = this.props;
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    if (payload.status === 1)
      return this.setState({
        payment: true,
        fetching: false,
      });
  }
  handleBayar(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (payload.status === 1)
      return this.setState({
        bayar: true,
        fetching: false,
        pay_url: payload.url,
        price: payload.price,
      });
  }
  bayar() {
    const { callAction, call_action } = this.props;

    let affiliate_id = localStorage.getItem("affiliate_id");
    let promo = localStorage.getItem("promo");

    callAction(call_action, "bayar", {
      endpoint: "/payment/url",
      data: {
        session_id: localStorage.getItem("session_id"),
        affiliate_id: affiliate_id !== null ? affiliate_id : 0,
        promo: promo !== null ? promo : "",
      },
      scenario: "post",
    });
    this.setState({
      fetching: true,
      payment: false,
    });
  }
  handleResult() {
    const { getResultAction, result } = this.props;
    console.log("handleResult", result);
    if (result.payload !== null) getResultAction();
  }
  handleGetResult(get_result) {
    if (
      typeof get_result.payload !== "undefined" &&
      get_result.payload !== null
    ) {
      /* if (get_result.payload.status === 1) {
        console.log("we are starting");
        this.setState({ finished: true });
      } else {
        console.log("xxx");
        this.setState({ start: true });
      }*/
      this.setState({ start: true });
    } else {
      //console.log("no result yet"); this.setState({ start: true });
    }
  }
  checkExistingResult() {
    const { getResultAction } = this.props;
    getResultAction();
  }
  initQuiz() {
    const { questionAction } = this.props;
    console.log("initQuiz");
    //current page
    let current_page = localStorage.getItem("page");

    if (current_page === null) localStorage.setItem("page", 0);

    //current questions
    let questions = localStorage.getItem("questions");

    if (typeof questions === "undefined" || questions === null)
      questionAction();
    //load questions
    else {
      this.loadQuestions(JSON.parse(questions));
      this.setState({
        page: current_page,
        questions: JSON.parse(questions),
      });
    }
    //current progress
    //when the user accidentally refresh the browser, we can still reload the progress somehow.
    let progress = localStorage.getItem("progress");
    if (progress === null) localStorage.setItem("progress", JSON.stringify([]));
    else
      this.setState({
        progresses: JSON.parse(progress),
      });
  }
  populateResult(data) {
    let fullscore = 0;
    for (var k in data) {
      if (k !== "created_at" && k !== "account_id")
        fullscore = data[k] > fullscore ? data[k] : fullscore;
    }
    let d = [];
    for (let i = 0; i < defaults.length; i++) {
      d[i] = defaults[i];
      d[i].score = data[defaults[i].name];
      d[i].fullMark = fullscore;
      console.log(d[i].score, d[i].fullMark);
    }

    this.setState({
      result_data: d,
      max: fullscore,
      finished:true,
    });
  }
  initClusters() {
    console.log("INIT CLUSTERS");
    const { resultAction } = this.props;
    const { questions, page } = this.state;

    let clusters = this.createCluster();
    let progresses = [0];
    for (let i = 0; i < questions.length; i++) {
      progresses.push(40);
    }
    let progress = localStorage.getItem("progress");
    if (progress !== null) progresses = JSON.parse(progress);
    let finished = false;
    if (parseInt(page) >= clusters.length && clusters.length > 0) {
      finished = true;
      /*resultAction({
        questions,
        progresses: progress
      });*/
    }
    this.setState({
      clusters,
      progresses,
      finished,
    });
  }
  loadQuestions(questions) {
    localStorage.setItem("questions", JSON.stringify(questions));
    let progress = localStorage.getItem("progress");
    if (progress === null) progress = [0];
    else progress = JSON.parse(progress);
    for (let i = 1; i <= questions.length; i++) {
      if (typeof progress[i] === "undefined") progress[i] = 40;
    }

    localStorage.setItem("progress", JSON.stringify(progress));
    this.setState({
      questions,
      progresses: progress,
    });
  }
  createCluster() {
    const { questions } = this.state;
    let clusters = [];
    let n_clusters = Math.ceil(questions.length / 5);
    let n = 0;
    for (let i = 0; i < n_clusters; i++) {
      let items = [];
      for (let j = 0; j < 5; j++) {
        let idx = n * 5 + j;
        if (typeof questions[idx] !== "undefined") {
          questions[idx].no = idx + 1;
          items.push(questions[idx]);
        }
      }
      clusters.push(items);
      n++;
    }
    console.log(clusters);
    return clusters;
  }
  handleNext() {
    const { resultAction } = this.props;
    const { page, clusters } = this.state;
    //save progresses
    this.saveProgresses();

    //proceed next page
    let nextPage = parseInt(page) + 1;

    if (nextPage >= clusters.length) {
      resultAction({
        session_id: localStorage.getItem("session_id"),
        questions: this.state.questions,
        progresses: this.state.progresses,
      });
      this.setState({ fetching: true, finished:true });
    } else {
      this.setState({
        page: nextPage,
      });
    }
    localStorage.setItem("page", nextPage);
  }
  saveProgresses() {
    const { progresses, questions } = this.state;
    for (let i = 1; i < questions.length; i++) {
      if (progresses[i] === null) progresses[i] = 40;
    }
    localStorage.setItem("progress", JSON.stringify(progresses));
  }
  register(data) {
    const { callAction, call_action, get_profile } = this.props;
    this.setState({
      registerData: data,
      fetching: true,
    });
    data.session_id = localStorage.getItem("session_id");

    callAction(call_action, "register_premium", {
      endpoint: "/register",
      data,
      scenario: "post",
    });
  }
  renderOld() {
    const {
      classes,
      result,
      get_result,
      history,
      resultAction,
      start,
      call_action,
    } = this.props;
    const {
      page,
      clusters,
      finished,
      progresses,
      result_data,
      chartkey,
      max,
      payment,
      fetching,
      bayar,
      pay_url,
      price,
    } = this.state;

    if (fetching)
      return (
        <div>
          <Header />
          <div style={{ marginTop: 100, textAlign: "center" }}>
            <CircularProgress />
          </div>
          <Footer />
        </div>
      );
    if (typeof call_action.validate_session === "undefined")
      return (
        <div>
          <Header />
          <CircularProgress />
          <Footer />
        </div>
      );
   /* if (bayar === true) {
      return (
        <div>
          <Header />
          <Bayar
            history={this.props.history}
            data={this.state.registerData}
            pay_url={pay_url}
            price={price}
          />
          <Footer />
        </div>
      );
    }*/
   /* if (payment === true) {
      const { name, email, phone } = this.props.location.state;
      return (
        <div>
          <Header />
          <Invoice
            history={this.props.history}
            data={this.state.registerData}
            onBayar={() => {
              this.bayar();
            }}
          />
          <Footer />
        </div>
      );
    }*/
    /*if (finished === true) {
      if (typeof this.props.location.state === "undefined")
        return this.props.history.push("/");
      const { name, email, phone } = this.props.location.state;
      history.push("/premium");
      return (
        <div>
          
        </div>
      );
    }*/

    if (clusters.length === 0 || start === false)
      return (
        <div style={{ textAlign: "center", margin: 60 }}>
          <CircularProgress />
          <Footer />
        </div>
      );

    if (page >= clusters.length) {
      return (
        <div>
          <Header />
          <QuizResult
            data={result_data}
            key={chartkey}
            max={max}
            paid={this.state.paid}
            content={
              typeof result.payload !== "undefined"
                ? result.payload !== null
                  ? result.payload.content
                  : null
                : null
            }
          />
          <Footer />
        </div>
      );
    }

    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {parseInt(page) === 0 ? (
                <p>
                  Mohon kerjakan test dibawah ini, dengan memilih jawaban,
                  sesuai dengan petunjuk yang diberikan pada tiap nomor. Baca
                  baik-baik, dan pastikan anda mengerti jawaban apa yang akan
                  anda pilih. Tiap jawaban anda, menentukan ketepatan hasil yang
                  akan berguna bagi anda sendiri.
                </p>
              ) : (
                <div style={{ marginBottom: 30 }}></div>
              )}

              {clusters[page].map((q, index) => {
                return (
                  <div
                    key={q.id}
                    style={{
                      padding: "8px 10px 20px 10px",
                      backgroundColor: "#f1f2f6",
                      borderRadius: 4,
                      marginBottom: 10,
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: "bold" }}>{q.no}.</span>{" "}
                      {q.question}
                    </div>
                    <div>
                      <div
                        style={{
                          width: "100%",
                          display: "inline-block",
                        }}
                      >
                        <Slider
                          defaultValue={10}
                          aria-labelledby="discrete-slider"
                          step={10}
                          marks
                          min={10}
                          max={70}
                          onChangeCommitted={(evt, value) => {
                            let p = progresses;
                            p[q.no] = value;
                            this.setState({
                              progresses: p,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "left",
                            verticalAlign: "top",
                            fontSize: "80%",
                          }}
                        >
                          <div
                            className={classes.bullet}
                            style={{
                              marginLeft: 0,
                            }}
                          ></div>
                          {q.answer_1}
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "inline-block",
                            textAlign: "center",
                            verticalAlign: "top",
                            fontSize: "80%",
                          }}
                        >
                          <div className={classes.bullet}></div>
                          {q.slots.length === 3 ? q.answer_2 : ""}
                        </div>

                        <div
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "right",
                            verticalAlign: "top",
                            fontSize: "80%",
                          }}
                        >
                          <div
                            className={classes.bullet}
                            style={{
                              marginRight: 0,
                            }}
                          ></div>
                          {q.slots.length === 3 ? q.answer_3 : q.answer_2}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleNext();
                  }}
                >
                  SELANJUTNYA
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
  render() {
    const {
      classes,
      result,
      get_result,
      history,
      resultAction,
      start,
      call_action,
    } = this.props;
    const {
      page,
      clusters,
      finished,
      progresses,
      result_data,
      chartkey,
      max,
      payment,
      fetching,
      bayar,
      pay_url,
      price,
    } = this.state;
    if (fetching)
    return (
      <>
        <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <div style={{ marginTop: 100, textAlign: "center" }}>
          <CircularProgress />
        </div>
        <Footer />
      </>
    );
  if (typeof call_action.validate_session === "undefined")
    return (
      <>
        <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <CircularProgress />
        <Footer />
      </>
    );
  if (bayar === true) {
    return (
      <>
        <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <Bayar
          history={this.props.history}
          data={this.state.registerData}
          pay_url={pay_url}
          price={price}
        />
        <Footer />
      </>
    );
  }
  if (payment === true) {
    const { name, email, phone } = this.props.location.state;
    return (
      <>
        <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <Invoice
          history={this.props.history}
          data={this.state.registerData}
          onBayar={() => {
            this.bayar();
          }}
        />
        <Footer />
      </>
    );
  }
 

  if (clusters.length === 0 || start === false)
    return (
      <>
      <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <CircularProgress />
        <Footer />
      </>
    );
    if(typeof clusters === 'undefined') return "";
    if (finished === true) {
      if (typeof this.props.location.state === "undefined")
        return this.props.history.push("/");
      const { name, email, phone } = this.props.location.state;
      return (
        <>
          <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
          <QuizResult
            data={result_data}
            key={chartkey}
            max={max}
            isLogin={true}
            old={{
              name,
              email,
              phone,
            }}
            content={
              typeof get_result.payload !== "undefined"
                ? get_result.payload !== null
                  ? get_result.payload.content
                  : null
                : null
            }
            onSubmit={(data) => {
              history.push("/premium");
              //this.register(data);
            }}
          />
        </>
       
      );
    }
   
    return (
      <>
        <Navbar loginProp={{ isLogin: localStorage.getItem("token") ? true : false }} />
        <HeaderFourtyEigthQuest/>
        
          <FormFourtyEight slideIndex={page*5} isLast={(parseInt(page)+1) === clusters.length ? true : false} questions={clusters[page]} previous={(progress)=>{
            window.scrollTo(0,0);
          //  console.log({previous:progress})
            let p = progresses;
            for(let k in progress){
              p[k] = progress[k];
            }
            this.setState({
              progresses: p,
              page: (parseInt(page)-1) < 0 ? 0 : (parseInt(page) - 1)
            })
          }} next={(progress)=>{
            window.scrollTo(0,0);
           // console.log({next:progress})
            let p = progresses;
            for(let k in progress){
              p[k] = progress[k];
            }
            this.setState({
              progresses: p,
            //  page: page < clusters.length ? parseInt(page)+1 : parseInt(page)
            })
            this.handleNext();
          }}
          onDone={(progress)=>{
            let p = progresses;
            for(let k in progress){
              p[k] = progress[k];
            }
            this.setState({
              progresses: p,
            })
            this.handleNext();
          }}/>
        
    </>
    );
  }
}

const mapStateToProps = (state) => {
  const { questions, result, get_result, call_action } = state;
  return {
    questions,
    result,
    get_result,
    call_action,
  };
};

const mapDispatchToProps = (dispatch) => ({
  questionAction: (data) => dispatch(QuestionAction(data)),
  resultAction: (data) => dispatch(ResultAction(data)),
  getResultAction: (data) => dispatch(GetResultAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(QuizContainer));
