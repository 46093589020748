import React, { useState } from "react";
import { Grid, CircularProgress, Button, TextField } from "@material-ui/core";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Legend
} from "recharts";


const accounting = require("accounting");
export default function Bayar(props) {
  const { data, confirm_url,toConfirm, pay_url,price } = props;

  return (
    <div style={{ marginTop: 100 }}>
      <Grid
        container
        style={{
          maxWidth: 800,
          marginTop: 60,
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Grid item xs={12} md={12}>
          <div style={{ margin: 30, textAlign: "center", fontSize: "120%" }}>
            <h1 id="-akses-halaman-detail-premium-anda-">
              SHIOKAYA PREMIUM
            </h1>
            <p>
              Jumlah yang harus dibayar:  Rp. {accounting.formatNumber(price,0,".")}
            </p>
            <p>
              Anda akan diarahkan ke mitra pembayaran kami.
            </p>
           
          </div>
          <div style={{textAlign:'center'}}>
            <a href={pay_url}>
            <Button variant={"contained"} color={'primary'}>PEMBAYARAN</Button>
            </a>
          </div>
          <div style={{marginTop:40,textAlign:"center"}}>
              
              <img src={"https://sandbox.ipaymu.com/asset/images/logo_cc.png"} style={{height:50}}/>
              <img src={"https://sandbox.ipaymu.com/asset/images/bank.png"} style={{height:50}}/>
              <img src={"https://sandbox.ipaymu.com/asset/images/qris.png"} style={{height:50}}/>
              <img src={"https://sandbox.ipaymu.com/asset/images/cstore.png"} style={{height:50}}/>
              <img src={"https://sandbox.ipaymu.com/asset/images/akulaku.png"} style={{height:50}}/>
              
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
