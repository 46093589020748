import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import RegisterAction from "../redux/actions/RegisterAction";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  action: {
    textAlign: "right"
  },
  topMargin: {
    marginTop: "30px"
  },
  filterButton: {
    marginTop: "20px"
  },
  logo: {
    width: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "120px"
  },
  loginForm: {
    marginTop: 30,
    maxWidth: 400
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5)
  },
  action: {
    textAlign: "center"
  }
});

class RegistrationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      fetching: false,
      done: false,
      error: false,
      message: "",
      snackbar: false,
      snackbarText:""
    };

    this.renderRegistrationDone = this.renderRegistrationDone.bind(this);
    this.renderRegistrationFailed = this.renderRegistrationFailed.bind(this);

  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { register } = this.props;
    if (_props.register !== register) {
      this.setState({
        fetching: register.fetching
      });
    }
    if (_props.register.payload !== register.payload) {
      if (typeof register.payload !== 'undefined' && register.payload !== null && register.payload.status === 1) {
        this.setState({
          done: true
        });
      }
    }
    if (_props.register.error !== register.error) {
      if (register.error) {
        this.setState({
          error: true,
          message: register.message
        });
      }
    }
  }

  renderRegistrationDone() {
    const { classes, history } = this.props;

    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <img src={require('../assets/logo-shiokaya.png')}/>
        </div>
        <Container className={classes.loginForm}>
          <Grid item xs={12}>
            <h3>Finalisasi Pendaftaran Anda </h3>
            <p>
              Selangkah lagi untuk memulai perjalanan anda untuk mengetahui profil kekayaan anda. 
              Silahkan periksa inbox E-Mail anda
              untuk Instruksi Pembayaran.
            </p>
            <p>
              Tips : Jika anda tidak menerima E-Mail di dalam inbox anda,
              silahkan cek folder Spam Email anda.
            </p>
          </Grid>

          <Grid item xs={12} className={classes.action}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                history.push("/login");
              }}
            >
              KEMBALI
            </Button>
          </Grid>
        </Container>
      </div>
    );
  }
  renderRegistrationFailed() {
    const { classes, history } = this.props;

    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>SHIOKAYA</h1>
        </div>
        <Grid Container style={{maxWidth: 600,marginLeft:'auto',marginRight:'auto'  }}>
          <Grid item xs={12}>
            <h3>Mohon Maaf</h3>
            <p>
              Kami tidak berhasil mendaftarkan anda ! Silahkan coba kembali beberapa saat lagi !
            </p>
          </Grid>

          <Grid item xs={12} className={classes.action}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                history.push(
                  "/login"
                );
              }}
            >
              KEMBALI
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  render() {
    const { classes,registerAction } = this.props;
    const { fetching, done, email, name, error, snackbar, snackbarText } = this.state;
    if (done) {
      return this.renderRegistrationDone();
    }
    if (error) {
      return this.renderRegistrationFailed();
    }
    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>SHIOKAYA</h1>
          <img src={require("../assets/rune.png")} style={{ height: 75 }} />
        </div>
        <Container className={classes.loginForm}>
          <Grid item xs={12}>
            <h3>Pendaftaran</h3>
            <p>
              Silahkan isi Nama Lengkap dan Alamat E-Mail anda dibawah ini :{" "}
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nama"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ name: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-Mail"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ email: evt.target.value })}
            />
          </Grid>
          {/*
          <Grid item xs={12}>
            <h4>Mahar</h4>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "150%",
                padding: 0,
                marginTop: "-17px",
                color: "#cc0000"
              }}
            >
              Rp. 99,000
            </p>
          </Grid>
            */}
          <Grid item xs={12} className={classes.action}>
            {!fetching ? (
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  if (name.length === 0 || email.length === 0) {
                    console.log("empty");
                  } else {
                    this.setState({
                      fetching: true
                    });
                    registerAction({
                      name,
                      email
                    })
                  }
                }}
              >
                DAFTAR
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Container>
        <Snackbar open={snackbar} autoHideDuration={6000}>
          {snackbarText}
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { register } = state;
  return {
    register
  };
};

const mapDispatchToProps = dispatch => ({
  registerAction: data => dispatch(RegisterAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RegistrationContainer));

//export default withStyles(styles)(LoginContainer);
