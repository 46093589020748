import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ConfirmAction from "../redux/actions/ConfirmAction";
import moment from "moment";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  action: {
    textAlign: "right"
  },
  topMargin: {
    marginTop: "30px"
  },
  filterButton: {
    marginTop: "20px"
  },
  logo: {
    width: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "120px"
  },
  loginForm: {
    marginTop: 30,
    maxWidth: 750
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5)
  },
  action: {
    textAlign: "center"
  }
});

class ConfirmContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nama_rekening: "",
      nama_bank: "",
      cabang_bank: "",
      email: "",
      nomor_rekening: "",
      nomor_telp: "",
      tanggal_transfer: moment().format("YYYY-MM-DDTHH:mm"),
      jumlah: "",
      fetching: false,
      done: false,
      error: false,
      message: "",
      snackbar: false,
      snackbarText: ""
    };

    this.renderConfirmDone = this.renderConfirmDone.bind(this);
    this.renderConfirmFailed = this.renderConfirmFailed.bind(this);
  }
  componentDidMount() {
    /*if(localStorage.getItem("token") === null){
      if(this.props.location.query.token !== null){
        localStorage.setItem("token", this.props.location.query.token);
      }
    }*/
    console.log(this.props.location);
    //localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { confirm } = this.props;
    if (_props.confirm !== confirm) {
      this.setState({
        fetching: confirm.fetching
      });
    }
    if (_props.confirm.payload !== confirm.payload) {
      
      if (typeof confirm.payload !== 'undefined' && confirm.payload !== null && confirm.payload.status === 1) {
        this.setState({
          done: true
        });
      }
    }
    if (_props.confirm.error !== confirm.error) {
      if (confirm.error) {
        this.setState({
          error: true,
          message: confirm.message
        });
      }
    }
  }

  renderConfirmDone() {
    const { classes, history } = this.props;

    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>SHIOKAYA</h1>
        </div>
        <Grid Container style={{ maxWidth: 600,marginLeft:'auto',marginRight:'auto' }}>
          <Grid item xs={12}>
            <h3>Konfirmasi Pembayaran anda telah kami terima !</h3>
            <p>
              Kami akan segera mengirimkan Token Login ke E-Mail anda setelah
              verifikasi pembayaran anda selesai.
            </p>
            <p>
              Tips : Jika anda tidak menerima E-Mail di dalam inbox anda,
              silahkan cek folder Spam Email anda.
            </p>
          </Grid>

          <Grid item xs={12} className={classes.action}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              KEMBALI
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  renderConfirmFailed() {
    const { classes, history } = this.props;

    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>SHIOKAYA</h1>
        </div>
        <Grid Container style={{ maxWidth: 600,marginLeft:'auto',marginRight:'auto'  }}>
          <Grid item xs={12}>
            <h3>Mohon Maaf</h3>
            <p>
              Konfirmasi pembayaran anda tidak berhasil kami simpan. Silahkan
              coba kembali beberapa saat lagi !
            </p>
          </Grid>

          <Grid item xs={12} className={classes.action}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                history.push(
                  "/confirm?token=" + this.props.location.query.token
                );
              }}
            >
              KEMBALI
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  render() {
    const { classes, confirmAction, location } = this.props;
    const {
       
      fetching,
      done,
      email,
      nama_rekening,
      nomor_rekening,
      nama_bank,
      cabang_bank,
      jumlah,
      nomor_telp,
      error,
      snackbar,
      snackbarText,
      tanggal_transfer,
    } = this.state;
    if (done) {
      return this.renderConfirmDone();
    }
    if (error) {
      return this.renderConfirmFailed();
    }

    //const {name,email,phone} = location.state
    return (
      <div className={classes.root}>
        <div
          style={{
            textAlign: "center"
          }}
        >
          <h1>SHIOKAYA</h1>
        </div>
        <Container className={classes.loginForm}>
          <Grid item xs={12}>
            <h3>KONFIRMASI PEMBAYARAN</h3>
            <p>Mohon isi detil pembayaran anda melalui form dibawah ini:</p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nama Lengkap"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ nama_rekening: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="E-Mail Pendaftaran"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ email: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nomor Rekening Asal"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt =>
                this.setState({ nomor_rekening: evt.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nama Bank"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ nama_bank: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Cabang"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ cabang_bank: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
         
             <TextField
                id="datetime-local"
                fullWidth
                variant="filled"
                label="Tanggal Transfer"
                type="datetime-local"
                defaultValue= {moment().format("YYYY-MM-DDTHH:mm")}
                className={classes.textField}
                onBlur={evt => this.setState({ tanggal_transfer: evt.target.value })}
                InputLabelProps={{
                shrink: true,
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Jumlah Transfer"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ jumlah: evt.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nomor Telpon / Ponsel yang dapat dihubungi"
              fullWidth
              variant="filled"
              placeholder=""
              style={{ marginBottom: 30 }}
              onBlur={evt => this.setState({ nomor_telp: evt.target.value })}
            />
          </Grid>

          {/*
          <Grid item xs={12}>
            <h4>Mahar</h4>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "150%",
                padding: 0,
                marginTop: "-17px",
                color: "#cc0000"
              }}
            >
              Rp. 99,000
            </p>
          </Grid>
            */}
          <Grid item xs={12} className={classes.action}>
            {!fetching ? (
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  if (nomor_rekening.length === 0 || jumlah.length === 0) {
                  } else {
                    this.setState({
                      fetching: true
                    });
                    confirmAction({
                      email,
                      nama_rekening,
                      nomor_rekening,
                      tanggal_transfer,
                      jumlah,
                      nama_bank,
                      nomor_telp,
                      cabang_bank
                    });
                  }
                }}
              >
                KIRIM
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Container>
        <Snackbar open={snackbar} autoHideDuration={6000}>
          {snackbarText}
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { confirm } = state;
  return {
    confirm
  };
};

const mapDispatchToProps = dispatch => ({
  confirmAction: data => dispatch(ConfirmAction(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConfirmContainer));

//export default withStyles(styles)(LoginContainer);
