import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Header from "./quiz/Header";
import CallAction from "../redux/actions/CallAction";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Footer from "./Footer";
import qs from "querystring";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  action: {
    textAlign: "right"
  },
  topMargin: {
    marginTop: "30px"
  },
  filterButton: {
    marginTop: "20px"
  },
  logo: {
    width: "150px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "120px"
  },
  loginForm: {
    marginTop: 30,
    maxWidth: 750
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5)
  },
  action: {
    textAlign: "center"
  }
});

class Sukses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nama_rekening: "",
      nama_bank: "",
      cabang_bank: "",
      email: "",
      nomor_rekening: "",
      nomor_telp: "",
      tanggal_transfer: moment().format("YYYY-MM-DDTHH:mm"),
      jumlah: "",
      fetching: false,
      done: false,
      error: false,
      message: "",
      snackbar: false,
      snackbarText: "",
      pos:'loading',
     
    };
    this.handleVerify = this.handleVerify.bind(this);
   
  }
  componentDidMount() {
    const {callAction, call_action} = this.props;
    /*if(localStorage.getItem("token") === null){
      if(this.props.location.query.token !== null){
        localStorage.setItem("token", this.props.location.query.token);
      }
    }*/
   
    let querystring = this.props.location.search.split("?").join("");
   

    let params = qs.parse(querystring);
    callAction(call_action,'verify_payment',{
      endpoint:'/payment/verify/' + (params ? params.trx : 0)
    });

    //localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
   
    if (_props.call_action.verify_payment !== call_action.verify_payment) {
      this.handleVerify(call_action.verify_payment)
    }
  }
  handleVerify(payload){
      if(payload === null) return;
      if(typeof payload.type === 'undefined') return;
      if(payload.type==='VOUCHER'){
        this.setState({pos:'affiliate'})
      }else{
        this.setState({pos:'success'});
      }
  }
  render() {
    const { classes, confirmAction, location, history } = this.props;
    const {
       
      fetching,
      done,
      email,
      nama_rekening,
      nomor_rekening,
      nama_bank,
      cabang_bank,
      jumlah,
      nomor_telp,
      error,
      snackbar,
      snackbarText,
      tanggal_transfer,
      pos
    } = this.state;
   

    //const {name,email,phone} = location.state

    if(pos === 'success') return (
      <div className={classes.root}>
        <Header />
        <Container className={classes.loginForm}>
          <Grid item xs={12} style={{textAlign:"center",marginTop:80}}>
            <h3>TERIMA KASIH ATAS PEMBAYARAN ANDA !</h3>
            <p>PENTING. Terima kasih atas pembayaran anda. Silahkan cek email yang anda gunakan saat mendaftar untuk memperoleh follow up dari pembayaran anda lebih lanjut.</p>
          </Grid>
          
          <Grid item xs={12} className={classes.action}>
            {!fetching ? (
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  history.push("/quiz");
                }}
              >
                LANJUT KE KUIS
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Container>
        <Snackbar open={snackbar} autoHideDuration={6000}>
          {snackbarText}
        </Snackbar>
      </div>
    );

    if(pos === "affiliate") return (<div className={classes.root}>
      <Header />
      <Container className={classes.loginForm}>
        <Grid item xs={12} style={{textAlign:"center",marginTop:80}}>
          <h3>TERIMA KASIH ATAS PEMBAYARAN ANDA !</h3>
          <p>PENTING. Terima kasih atas pembelian voucher anda. Untuk melihat kode dan status voucher, silahkan masuk ke dashboard afiliasi anda.</p>
        </Grid>
        
        <Grid item xs={12} className={classes.action}>
          {!fetching ? (
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => {
                document.location = process.env.REACT_APP_AFFILIATE_URL;
              }}
            >
              LANJUT
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Container>
      <Snackbar open={snackbar} autoHideDuration={6000}>
        {snackbarText}
      </Snackbar>
    </div>);
    return "";
  }
}


const mapStateToProps = state => {
  const { guestLogin, call_action } = state;
  return {
    guestLogin,
    call_action
  };
};

const mapDispatchToProps = dispatch => ({
  loginAsGuest: data => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(Sukses));
