import withAuth from "./withAuth";
import LoginContainer from './Components/LoginContainer';
import RegistrationContainer from './Components/RegistrationContainer';
import QuizContainer from './Components/quiz/QuizContainer';
import TestContainer from './Components/quiz/TestContainer';
import BuyContainer from './Components/buy/BuyContainer';
import ReturnedContainer from './Components/ReturnedContainer';

import ConfirmContainer from './Components/ConfirmContainer';
import LandingContainer from './Components/LandingContainer2';
import ProfileContainer from './Components/quiz/ProfileContainer';
import PremiumProfileContainer from './Components/quiz/PremiumProfileContainer';
import PremiumProfileTestContainer from './Components/quiz/PremiumProfileTestContainer';

import attachProfile from "./attachProfile";
import Sukses from "./Components/Sukses";
const routes = [
    {
      path: "/",
      exact: true,
      component: LandingContainer
    },
    {
        path: "/login",
        exact: true,
        component: LoginContainer
      },
      {
        path: "/register",
        exact: true,
        component: RegistrationContainer
      },
      {
        path: "/test",
        exact: true,
        component: (TestContainer)
      },
    {
        path: "/quiz",
        exact: true,
        component: withAuth(QuizContainer)
      },
      {
        path: "/buy",
        exact: true,
        component: (BuyContainer)
      },
      {
        path: "/confirm",
        exact: true,
        component: (ConfirmContainer)
      },
      {
        path: "/profile/alchemist",
        exact: true,
        component: attachProfile(ProfileContainer, 'alchemist')
      },
      {
        path: "/profile/wizard",
        exact: true,
        component: attachProfile(ProfileContainer, 'wizard')
      },
     
      {
        path: "/profile/bard",
        exact: true,
        component: attachProfile(ProfileContainer, 'bard')
      },
     
      {
        path: "/profile/knight",
        exact: true,
        component: attachProfile(ProfileContainer, 'knight')
      },
     
      {
        path: "/profile/arbiter",
        exact: true,
        component: attachProfile(ProfileContainer, 'arbiter')
      },
     
      {
        path: "/profile/merchant",
        exact: true,
        component: attachProfile(ProfileContainer, 'merchant')
      },
      {
        path: "/profile/priest",
        exact: true,
        component: attachProfile(ProfileContainer, 'priest')
      },
     
      {
        path: "/profile/marshal",
        exact: true,
        component: attachProfile(ProfileContainer, 'marshal')
      },
      {
        path: "/premium",
        exact: true,
        component: withAuth(PremiumProfileContainer)
      },
      {
        path: "/premium/latest",
        exact: true,
        component: withAuth(PremiumProfileTestContainer)
      },
      {
        path: "/success",
        exact: true,
        component: Sukses
      },
      {
        path: "/return",
        exact: true,
        component: ReturnedContainer
      },
  ];

  export default routes;