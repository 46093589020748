import { combineReducers } from "redux";
import login from "./login";
import register from "./register";
import questions from "./questions";
import result from "./result";
import get_result from './get_result';
import confirm from './confirm';
import guestLogin from './guestLogin';
import call_action from "./call_action";




export default combineReducers({
  login,
  register,
  questions,
  result,
  get_result,
  confirm,
  guestLogin,
  call_action,
});