import React from "react";
import { Grid, CircularProgress, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

import Invoice from "./InvoiceAuto";
import Bayar from "./Bayar";
import loginAsGuestAction from "../../redux/actions/loginAsGuestAction";

import QuestionAction from "../../redux/actions/QuestionAction";
import ResultAction from "../../redux/actions/ResultAction";
import GetResultAction from "../../redux/actions/GetResultAction";
import Header from "./Header";
import CallAction from "../../redux/actions/CallAction";
import Footer from "../Footer";
import Navbar from "../parts/Navbar";

const _ = require("lodash");
const qs = require("qs");

class BuyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      name: null,
      email: null,
      phone: null,
      voucher: null,
      confirm: false,
      bayar: false,
      payment: false,
      price: null,
      pay_url: null,
      registerData: null,
      account_id: null,
      flag_quiz: false,
    };
    this.register = this.register.bind(this);
    this.handleRegistration = this.handleRegistration.bind(this);
    this.bayar = this.bayar.bind(this);
    this.handleBayar = this.handleBayar.bind(this);
  }

  componentDidMount() {
    const { location, call_action, callAction } = this.props;
    let token = localStorage.getItem("token");
    let chunk = location.search.split("?");
    let qparams = chunk.length > 0 ? qs.parse(chunk[1]) : null;
    localStorage.setItem(
      "affiliate_id",
      qparams.affiliate_id ? qparams.affiliate_id : 0
    );
    if (typeof qparams.promo !== "undefined") {
      localStorage.setItem("promo", qparams.promo);
    }
    
    if (!token) this.props.loginAsGuest();
    else {
      callAction(call_action, "me", {
        endpoint: "/me",
      });
      this.setState({
        fetching: true,
      });
    }
  }
  componentDidUpdate(_props, _states) {
    const {
      guestLogin,
      questions,
      resultAction,
      result,
      get_result,
      call_action,
      callAction,
    } = this.props;
    const { finished, page, clusters, start } = this.state;

    if (call_action.register_premium !== _props.call_action.register_premium) {
      this.handleRegistration(call_action.register_premium);
    }
    if (call_action.bayar !== _props.call_action.bayar) {
      this.handleBayar(call_action.bayar);
    }
    if (call_action.me !== _props.call_action.me) {
      this.handleMe(call_action.me);
    }
    if (_props.guestLogin.payload !== guestLogin.payload) {
      this.setState({
        fetching: guestLogin.fetching,
      });
      if (typeof guestLogin.payload === "undefined") return;
      if (guestLogin.payload !== null) {
        localStorage.setItem("token", guestLogin.payload.access_token);
        localStorage.setItem("session_id", guestLogin.payload.user.session_id);
        localStorage.setItem("name", guestLogin.payload.user.name);
        callAction(call_action, "pretests", {
          endpoint: "/pretests",
          data: {
            per_page: 20,
            orderBy: {
              field: "id",
            },
            orderDirection: "asc",
          },
        });
      }
    }
  }

  handleRegistration(payload) {
    const { history } = this.props;
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    if (payload.status === 1) {
      if(!this.state.flag_quiz) localStorage.setItem("token", payload.access_token);
      localStorage.setItem("session_id", payload.user.session_id);
      localStorage.setItem("name", payload.user.name);
      this.bayar();
      return this.setState({
        payment: true,
      });
    }
  }
  handleBayar(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (payload.status === 1)
      return this.setState({
        bayar: true,
        fetching: false,
        pay_url: payload.url,
        price: payload.price,
      });
  }
  handleMe(payload) {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (payload.status === 1)
      return this.setState({
        account_id: payload.account_id,
        flag_quiz: payload.flag_quiz,
        fetching: false,
      });
  }
  bayar() {
    const { callAction, call_action } = this.props;

    let affiliate_id = localStorage.getItem("affiliate_id");
    let promo = this.state.voucher;

    callAction(call_action, "bayar", {
      endpoint: "/payment/url",
      data: {
        session_id: localStorage.getItem("session_id"),
        affiliate_id: affiliate_id !== null ? affiliate_id : 0,
        promo: promo !== null ? promo : "",
      },
      scenario: "post",
    });
    this.setState({
      fetching: true,
      payment: false,
    });
  }

  register(data) {
    const { callAction, call_action, get_profile } = this.props;
    this.setState({
      registerData: data,
      fetching: true,
    });
    data.session_id = localStorage.getItem("session_id");

    callAction(call_action, "register_premium", {
      endpoint: "/register",
      data,
      scenario: "post",
    });
  }
  render() {
    const {
      classes,
      result,
      get_result,
      resultAction,
      start,
      call_action,
    } = this.props;
    const {
      fetching,
      pay_url,
      name,
      email,
      phone,
      voucher,
      confirm,
      price,
      bayar,
    } = this.state;

    if (fetching)
      return (
        <div>
          <Navbar
            loginProp={{
              isLogin: localStorage.getItem("token") ? true : false,
            }}
          />
          <div style={{ marginTop: 100, textAlign: "center" }}>
            <CircularProgress />
          </div>
          <Footer />
        </div>
      );
    if (bayar === true) {
      if (parseFloat(price) === 0)
        return (
          <div>
            <Navbar
              loginProp={{
                isLogin: localStorage.getItem("token") ? true : false,
              }}
            />
            <div style={{ marginTop: 100 }}>
              <Grid
                container
                style={{
                  maxWidth: 800,
                  marginTop: 60,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Grid item xs={12} md={12}>
                  <div
                    style={{
                      margin: 30,
                      textAlign: "center",
                      fontSize: "120%",
                    }}
                  >
                    <h1 id="-akses-halaman-detail-premium-anda-">
                      SELAMAT BERGABUNG DI SHIOKAYA!
                    </h1>
                    <p>
                      Pendaftaran anda berhasil. Silahkan cek email anda untuk
                      mendapatkan Token anda!
                    </p>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => {
                        document.location = "/login";
                      }}
                    >
                      LOGIN
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Footer />
          </div>
        );
      else
        return (
          <div>
            <Navbar
              loginProp={{
                isLogin: localStorage.getItem("token") ? true : false,
              }}
            />
            <Bayar
              history={this.props.history}
              data={this.state.registerData}
              pay_url={pay_url}
              price={price}
            />
            <Footer />
          </div>
        );
    }
    if (this.state.flag_quiz)
      return (
        <div>
          <Navbar
            loginProp={{
              isLogin: localStorage.getItem("token") ? true : false,
            }}
          />
          <div style={{ marginTop: 100 }}>
            <Grid
              container
              style={{
                maxWidth: 800,
                marginTop: 60,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Grid item xs={12} md={12}>
                <div
                  style={{ margin: 30, fontSize: "120%" }}
                >
                  <div style={{textAlign:'center'}}>
                    <h3 id="-akses-halaman-detail-premium-anda-">
                      UPDATE PROFIL SHIOKAYA
                    </h3>
                  </div>
                  
                  <p>
                  Saya mengerti bahwa kondisi <strong>ShioKaya</strong> saya saat ini, sangat dipengaruhi oleh pola pikir dan kondisi yang saya alami dalam proses kehidupan saya. Berdasarkan kesadaran ini saya ingin melakukan update atas profil <strong>ShioKaya</strong> saya.
                  </p>
                  <p>
                  Saya mengerti bahwa profil <strong>ShioKaya</strong> saya bisa tetap ataupun berubah, sesuai dengan kondisi saya saat ini. 

                  </p>
                  <p>
                  Karena saya bagian dari mitra <strong>ShioKaya</strong>, maka saya berhak untuk <strong>discount sebesar 50%</strong>. 
                  </p>
                  
                </div>
                <div style={{ textAlign: "center" }}>
                  <a href={pay_url}>
                    <Button variant={"contained"} color={"primary"} onClick={()=>{
                      document.location="/premium";
                    }} style={{marginRight:20}}>
                      BATAL
                    </Button>
                  </a>
                  <a href={pay_url}>
                    <Button variant={"contained"} color={"primary"} onClick={()=>{
                      this.register({
                        account_id: this.state.account_id
                      })
                    }}>
                      LANJUT
                    </Button>
                  </a>
                </div>
                
              </Grid>
            </Grid>
          </div>
          <Footer />
        </div>
      );
    return (
      <div>
        <Navbar
          loginProp={{ isLogin: localStorage.getItem("token") ? true : false }}
        />
        {confirm ? (
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: 10,
              marginTop: 85,
              borderRadius: 10,
              textAlign: "center",
              maxWidth: 650,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4 style={{ margin: 0 }}>Nama Lengkap</h4>
                <span>{name}</span>
              </Grid>
              <Grid item xs={12}>
                <h4 style={{ margin: 0 }}>Email</h4>
                <span>{email}</span>
              </Grid>
              <Grid item xs={12}>
                <h4 style={{ margin: 0 }}>Nomor HP</h4>
                <span>{phone}</span>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Pastikan data yang anda isi diatas sudah benar. Kami tidak
                  akan bertanggung jawab jika akses premium tidak berhasil
                  dikirimkan setelah pembayaran berhasil ke email anda jika
                  email yang anda masukkan tidak sesuai.
                </p>
                <Grid container spacing={3}>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        this.setState({ confirm: false });
                      }}
                    >
                      PERBAIKI DATA
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.register({
                          name,
                          email,
                          phone,
                        });
                      }}
                    >
                      YA, LANJUTKAN
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: 10,
              marginTop: 85,
              borderRadius: 10,
              textAlign: "center",
              maxWidth: 650,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h3>Silahkan lengkapi data anda terlebih dahulu:</h3>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nama Lengkap"
                  fullWidth
                  variant="filled"
                  placeholder=""
                  defaultValue={name}
                  onBlur={(evt) => this.setState({ name: evt.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="E-Mail"
                  fullWidth
                  variant="filled"
                  placeholder=""
                  defaultValue={email}
                  onBlur={(evt) => this.setState({ email: evt.target.value })}
                />
                <p
                  style={{
                    padding: 0,
                    textAlign: "left",
                    margin: 0,
                  }}
                >
                  Akses premium anda akan kami kirimkan melalui email.
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nomor HP"
                  fullWidth
                  variant="filled"
                  placeholder=""
                  defaultValue={phone}
                  onBlur={(evt) => this.setState({ phone: evt.target.value })}
                />
                <p
                  style={{
                    padding: 0,
                    textAlign: "left",
                    margin: 0,
                  }}
                >
                  Nomor HP anda akan kami gunakan untuk korespondensi dengan
                  anda.
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Kode Voucher"
                  fullWidth
                  variant="filled"
                  placeholder=""
                  onBlur={(evt) => this.setState({ voucher: evt.target.value })}
                />
                <p
                  style={{
                    padding: 0,
                    textAlign: "left",
                    margin: 0,
                  }}
                ></p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      confirm: true,
                    });
                  }}
                >
                  SELANJUTNYA
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { guestLogin, questions, result, get_result, call_action } = state;
  return {
    questions,
    result,
    get_result,
    call_action,
    guestLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  questionAction: (data) => dispatch(QuestionAction(data)),
  resultAction: (data) => dispatch(ResultAction(data)),
  getResultAction: (data) => dispatch(GetResultAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
  loginAsGuest: (data) => dispatch(loginAsGuestAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(BuyContainer));
