import React from "react";
import { BackgroundLayerCopy1Image } from "../images";
import styled from "styled-components";

const Row = styled.div`
  .picture {
    margin-left: 20px;
    width: 80%;
    heigth: 80%;
  }
  .text {
    margin-top: 40px;
  }
  p {
    font-size: 100%;
    display: inline;
    @font-face {
      font-family: "Poppins SemiBold";
      src: local("Poppins SemiBold"),
        url(../../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
    }
    font-family: "Poppins SemiBold";
  }
  @media screen and (max-width: 420px) {
    .picture {
      margin-left: 10px;
      position: absolute;
      width: 30%;
      height: auto;
    }
    .text {
      margin-top: 10px;
      margin-left: 150px;
      font-size: 100%;
    }
  }
  .judul {
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
    text-align: center;
  }
  .title {
    display: flex;
    flex-wrap: nowrap;
  }
`;

const Circle = styled.div`
  margin-left: 20px;
  margin-top: -10px;
  width: 40px;
  height: 40px;
  background: #8ebc50;
  border-radius: 100%;
`;

const warna = {
  Yellow: "#f1c40f",
  Red: "#e74c3c",
  Orange: "#f39c12",
  "Light Green": "#81ecec",
  Green: "#27ae60",
  Tosca: "#16a085",
  Blue: "#3498db",
  Purple: "#9b59b6",
};
function ProfileResult(props) {
  const { content } = props;
  const { title, photo_url } = content.content;
  return (
    <Row>
      <div className="row clearfix">
        <div className="col-md-12 col-sm-12">
          <div style={{ textAlign: "center" }}>
            <p className="judul">
              Elemen{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    content.content.nama + " (" + content.content.simbol + ")",
                }}
              ></span>
            </p>
          </div>
        </div>
      </div>
      <div className="row clearfix mt-4">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <img
            src={content.content.logo_url}
            alt={BackgroundLayerCopy1Image.alt}
            className="picture"
          />
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
          <div className="text">
            <div className="title">
              <p className="color">Warna {content.content.warna} </p>
              <Circle style={{ background: warna[content.content.warna] }} />
            </div>
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: content.content.deskripsi_warna,
              }}
            />
          </div>
        </div>
      </div>
    </Row>
  );
}

export default ProfileResult;
