import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { look10pngImage, prevImage, nextImage } from "../images";
import { Link } from "react-router-dom";
import FourtyEightQuest from "../FourtyEightQuest.js";

const Button = styled.div`
  position: relative;
  p:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 420px) {
    font-size: 14px;
    p.align-right {
      margin-top: -35px;
    }
  }
`;

const FormFont = styled.section`
  @font-face {
    font-family: "Poppins Regular";
    src: local("Poppins Regular"),
      url(../../assets/fonts/Poppins-Regular.ttf) format("truetype");
  }
  font-family: "Poppins Regular";
  font-size: 25px;
`;
export default function(props){
  const {questions,slideIndex,previous,next,onDone, isLast} = props;
  const [progress, setProgress] = useState([]);
  const [update, setUpdate] = useState(0);
  return (
    <FormFont className="container mx-center">
      <div className="content-lg" style={{ marginTop: 30 }}>
        <input
          onChange={(e) => this.slider.slickGoTo(e.target.value)}
          value={slideIndex}
          className="form-control"
          type="range"
          min={0}
          max={48}
        />
        <form>
          {questions ? (
              questions.map((q, index) => {
                return (
                  <div className="card" key={index}>
                    <FourtyEightQuest
                      key={q.no}
                      number={q.no}
                      question={q.question}
                      answer_1={q.answer_1}
                      answer_2={q.answer_2}
                      answer_3={q.answer_3}
                      name={q.no}
                      onChange={(answer) => {
                        let p = progress;
                        p[q.no] = answer;
                       
                        setProgress(p);
                        setUpdate(update+1);
                      }}
                    />
                    {q.no === 48 ? (
                      <div className="content">
                        <div className="row clearfix mx-auto">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={look10pngImage.url}
                                alt={look10pngImage.alt}
                                width="70%"
                                height="auto"
                                style={{
                                  position: "relative",
                                  marginTop:30,
                                  marginBottom: 30,
                                  zIndex: "12",
                                  margin: "center",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mx-auto my-auto">
                            <div style={{textAlign:'center'}}>
                              <Link
                                className="btn btn-lg btn-round bg-purple"
                                onClick={() => {
                                  onDone(progress);
                                }}
                              >
                                lihat hasilnya
                              </Link>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })
          ) : null}
         

          <Button>
            <div className="row clearfix" style={{ marginTop: 30 }}>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <p className="prev" onClick={()=>{
                  previous(progress);
                }}>
                  <span className="mr-2">
                    <img
                      src={prevImage.url}
                      alt={prevImage.alt}
                      width="10%"
                      height="auto"
                    />
                  </span>
                  <strong>Kembali</strong>
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                {!isLast ? (
                   <p className="align-right" onClick={()=>{
                    next(progress);
                  }}>
                    <strong>Selanjutnya</strong>
                    <span className="ml-2">
                      <img
                        src={nextImage.url}
                        alt={nextImage.alt}
                        width="10%"
                        height="auto"
                      />
                    </span>
                  </p>
                ) : null}
               
              </div>
            </div>
          </Button>
        </form>
      </div>
    </FormFont>
  );
};


