import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Link
} from "@material-ui/core";
import { Instagram } from "@material-ui/icons";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import loginAsGuestAction from "../redux/actions/loginAsGuestAction";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Header from "./quiz/Header";
import BigBackgroundImage from "../assets/img1.jpg";
import BigBackgroundImage2 from "../assets/img2.jpg";

import Footer from "./Footer";
import YouTube from 'react-youtube';


import CallAction from "../redux/actions/CallAction";

const qs = require('qs');

class ReturnedContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: "",
      page: 1,
      content: null,
      paid: false,
      transaction_id:null,
      va:null,
      channel:null,
      amount:null,
    
    };
    this.handlePaymentInfo = this.handlePaymentInfo.bind(this);

  }
  componentDidMount() {
    const { call_action, callAction, location } = this.props;
    let chunk = location.search.split("?");
    let qparams = (chunk.length > 0) ? qs.parse(chunk[1]) : null;
    let trx_id = qparams.trx_id;

    callAction(call_action, 'payment_info',{
      endpoint:'/payment/info',
      scenario:'get',
      data:{
        trx_id
      }
    });
    

  }
  componentDidUpdate(_props, _states) {
    const { guestLogin, call_action } = this.props;
    if(call_action.payment_info !== _props.call_action.payment_info){
      this.handlePaymentInfo(call_action.payment_info);
    }
  }
  handlePaymentInfo(payload){
    if(typeof payload === 'undefined') return;
    if(payload === null) return;
    const {pgw,transaction} = payload;
    if(typeof pgw === 'undefined') return;
    if(typeof transaction === 'undefined') return;

    let paid = false;
    let va = null;
    let channel = null;
    let amount = 550000;
    let transaction_id = null;
    if(parseInt(transaction.transaction_status)===1){
      paid = true;
    }

   
    va = pgw.va;
    channel = pgw.channel;
    amount = pgw.channel==="bca" ? pgw.uniqamount : pgw.total;
    transaction_id = pgw.transaction_id;
    this.setState({
      paid,
      va,
      channel,
      amount,
      transaction_id
    })
  }
  render() {
    const {history} = this.props;
    const {paid, transaction_id,channel,amount,va} = this.state;
    return (
      <div>
        <Header />
      
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: 40,
                marginTop: 85,
                borderRadius: 10,
              
                maxWidth: 650,
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              {paid ? (
                <div>
                <p>Terima kasih untuk tindakan yang anda lakukan untuk bisa mengetahui profil kesuksesan alami anda. </p>
                <p>Untuk analisa dan akses ke profil anda ini, silahkan menyelesaikan proses pembayaran anda terlebih dahulu.</p>
                <p>Kami akan mengirimkan token ke email anda, setelah anda menyelesaikan proses pembayaran tersebut.</p>
                <div style={{textAlign:'center'}}>
                    <Button variant={'contained'} color={'primary'} onClick={()=>{
                      history.push('/login');
                    }}>Login</Button>
                  </div>
               </div>
              ) : (
                <div>
               <p>
               Terima kasih untuk tindakan yang anda lakukan untuk bisa mengetahui profil kesuksesan alami anda. </p>
               <p>Untuk analisa dan akses ke profil anda ini, silahkan menyelesaikan proses pembayaran anda terlebih dahulu. Kami akan mengirimkan token ke email anda, setelah anda menyelesaikan proses pembayaran tersebut.
               </p>
               <div>
                <h1>Kode Transaksi: {transaction_id}</h1>
                <h3>Sebesar Rp. {amount !== null ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."): 0}</h3>
                <h3>Channel Pembayaran: {channel}</h3>
                <h3>Transfer ke {va}</h3>
                </div>
              </div>
              )}
              
              
            </div>
          
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { guestLogin, call_action } = state;
  return {
    guestLogin,
    call_action
  };
};

const mapDispatchToProps = dispatch => ({
  loginAsGuest: data => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(ReturnedContainer));
