import React from "react";
import {
  Grid,
  CircularProgress,
  Slider,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import Header from "./Header";
import Footer from "../Footer";
import YouTube from "react-youtube";
import loginAsGuestAction from "../../redux/actions/loginAsGuestAction";

import CallAction from "../../redux/actions/CallAction";

const _ = require("lodash");

const TextLabel = (props) => {
  const { name, text } = props;
  return (
    <div>
      <h4
        style={{
          fontSize: "120%",
          margin: 0,
          display: "inline-block",
          marginRight: 10,
        }}
      >
        {name}
      </h4>
      <span style={{ fontSize: "120%" }}>{text}</span>
    </div>
  );
};

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
    };
  }

  componentDidMount() {
    const { callAction, call_action, slug } = this.props;
    callAction(call_action, "profile", {
      endpoint: "/profile/" + slug,
    });
    let token = localStorage.getItem("token");

    if (typeof token === "undefined") {
      this.props.history.push("quiz");
    } else {
      //ask for new token
      this.props.loginAsGuest();
    }
  }
  componentDidUpdate(_props, _states) {}

  render() {
    const { classes, call_action, history } = this.props;
    if (typeof call_action.profile === "undefined") return null;
    let payload = call_action.profile;
    if (typeof payload === "undefined") return null;
    if (payload === null) return null;
    if (typeof payload.data === "undefined") return null;

    let content = payload.data;

    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid
            container
            style={{
              maxWidth: 800,
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid item xs={12} md={12}>
              <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0 }}>{content.title.toUpperCase()}</h1>
                <h3 style={{ margin: 0 }}>({content.tagline})</h3>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  textAlign: window.innerWidth < 600 ? "center" : "left",
                  marginTop: 40,
                  marginBottom: 60,
                  padding: 30,
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    width: 300,
                    textAlign: window.innerWidth < 600 ? "center" : "left",
                  }}
                >
                  <TextLabel
                    name={"Elemen"}
                    text={content.nama + "(" + content.simbol + ")"}
                  />
                </div>

                <img
                  src={content.logo_url}
                  style={{
                    width: 150,
                  }}
                />
                <div
                  style={{
                    margin: "0 auto",
                    width: 300,
                    textAlign: window.innerWidth < 600 ? "center" : "left",
                  }}
                >
                  <TextLabel name={"Warna"} text={content.warna} />
                  <div
                    style={{
                      marginTop: 5,
                      width: 120,
                      height: 30,
                      backgroundColor: content.color,
                      marginBottom: 20,
                    }}
                  ></div>
                  <div>
                    <p>{content.fungsi}</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={content.photo_url}
                  style={{
                    width: window.innerWidth > 600 ? "50%" : 300,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  marginTop: window.innerWidth > 600 ? 30 : 30,
                  padding: "10px 0px 0px 10px",
                }}
              >
                <div>
                  <p dangerouslySetInnerHTML={{ __html: content.karakter }} />
                  <p dangerouslySetInnerHTML={{ __html: content.summary }} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div
                style={{
                  marginTop: 10,
                  width: "100%",
                  height: 30,
                  backgroundColor: content.color,
                  marginBottom: 5,
                  borderBottom: "10px #000000 solid",
                }}
              ></div>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{ marginBottom: 30, textAlign: "center", marginTop: 30 }}
              >
                <YouTube
                  videoId="B9Wz-RwYr_Y"
                  opts={{
                    height: window.innerWidth < 640 ? "180" : "280",
                    width: window.innerWidth < 640 ? "340" : "640",
                  }}
                />
              </div>
              <div style={{ fontSize: "120%", lineHeight: "150%" }}>
                Ikuti <strong>test potensi model keberhasilan diri anda</strong>
                , dan dapatkan deskripsi "akurat" tentang bagaimana{" "}
                <strong>potensi anda</strong> untuk bisa mencapai{" "}
                <strong>keberhasilan dan kekayaan</strong>, dengan efektif dan
                efisien.
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: 30,
                }}
              ></div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ fontSize: "120%", lineHeight: "150%" }}>
                <p>
                  Anda cukup mengalokasikan waktu selama 5 menit, mengisi 12
                  pertanyaan singkat shiokaya, untuk bisa memperoleh gambaran
                  umum tentang potensi model keberhasilan diri anda.
                </p>
                <p>
                  <strong>Shiokaya</strong> merupakan alat ukur untuk potensi
                  model keberhasilan, yaitu cuplikan informasi tentang bagaimana
                  anda bisa menggunakan potensi yang selama ini ada di dalam
                  diri anda, untuk bisa mencapai keberhasilan lebih cepat dan
                  akurat.
                </p>
                <p>
                  5 hal apa yang bisa anda lakukan setelah anda mendapatkan
                  gambaran umum anda melalui shiokaya ini:
                </p>
                <ol
                  style={{
                    padding: 0,
                    paddingLeft: 15,
                  }}
                >
                  <li>Melakukan penyesuaian dalam cara kerja anda</li>
                  <li>Mengerti bagaimana anda bisa men-tune-up bisnis anda</li>
                  <li>
                    Menyadari apa yang menjadi kekuatan maupun tantangan diri
                    anda
                  </li>
                  <li>
                    Mengerti model rekan atau karyawan yang anda butuhkan untuk
                    berhasil lebih cepat
                  </li>
                  <li>
                    Mampu untuk mencapai sukses dengan cara yang lebih
                    menyenangkan dalam mencapai tujuan anda
                  </li>
                </ol>
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: 30,
                }}
              >
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"large"}
                  onClick={() => {
                    history.push("/test");
                  }}
                >
                  MULAI
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action,guestLogin} = state;
  return {
    call_action,
    guestLogin
  };
};

const mapDispatchToProps = (dispatch) => ({
    loginAsGuest: data => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(ProfileContainer));
