import Api from "../../Services/Api"; 

const api = new Api();

export default (data) => dispatch => {
    
    dispatch({
        type: 'LOGIN',
        data
    })
    
    api.login(data).then((response)=>{
        
        if(typeof response.access_token !== 'undefined'){
            dispatch({
                type:"LOGIN_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"LOGIN_ERROR",
                message: response.error
            })
        }
        
    })
  
}