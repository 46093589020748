import React from "react";
import styled from "styled-components";

const Role = styled.section`
.jumbotron {
  background-color: #F8F8F5;
}
h4 {
  margin-top:0;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}

@media screen and (max-width: 420px) {
  h4 {
    margin-top:0;
  }
  h4.subtitle{

  }
}
`;


function RoleModel(props) {
  const {content} = props;
  let roleModel = [];
  let roleModelNumber = 7;
 
  return (
    <Role className="container">
      <div className="jumbotron">
        <h4 className="text-center">Role model - Tokoh terkenal</h4>
        
          <h4 className="subtitle">Alchemist</h4>
        
        <div className="row clearfix">
        {content.shio_profesi.alchemist.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Wizard</h4>
        <div className="row clearfix">
        {content.shio_profesi.wizard.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Bard</h4>
        <div className="row clearfix">
        {content.shio_profesi.bard.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Knight</h4>
        <div className="row clearfix">
        {content.shio_profesi.knight.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Arbiter</h4>
        <div className="row clearfix">
        {content.shio_profesi.arbiter.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Merchant</h4>
        <div className="row clearfix">
        {content.shio_profesi.merchant.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
         <h4 className="subtitle">Priest</h4>
        <div className="row clearfix">
        {content.shio_profesi.priest.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
        <h4 className="subtitle">Marshal</h4>
        <div className="row clearfix">
        {content.shio_profesi.marshal.map((item, index) => {
                      return (
                        <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                          <p><strong style={{ margin: 0 }}>{item.name}</strong></p>
                          <p><i>{item.profesi}</i></p>
                        </div>
                      );
          })}
        </div>
      </div>
    </Role>
  );
}

export default RoleModel;
