import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import LoginAction from "../redux/actions/login";
import LoginContainerStyles from "./styles/LoginContainerStyles";
import Navbar from "./parts/Navbar";
import FormLogin from "./parts/FormLogin";
import Footer from "./Footer";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      fetching: false,
      success: false,
      session_id: "",
      snackbar: false,
      snackbarText: "",
      result: false,
    };
  }
  componentDidMount() {
    localStorage.clear();
  }
  componentDidUpdate(_props, _states) {
    const { login } = this.props;
    if (_props.login !== login) {
      if (_props.login.fetching !== login.fetching) {
        this.setState({
          fetching: login.fetching,
        });
      }
      if (_props.login.payload !== login.payload && login.payload !== null) {
        if (typeof login.payload !== "undefined") {
          localStorage.setItem("token", login.payload.access_token);
          localStorage.setItem("session_id", login.payload.user.session_id);
          localStorage.setItem("name", login.payload.user.name);
          localStorage.setItem(
            "result",
            login.payload.results.length > 0 ? true : false
          );
          this.setState({
            success: true,
            result: login.payload.results.length > 0 ? true : false,
          });
        }
      }
      if (_props.login.error !== login.error) {
        if (login.error) {
          this.setState({
            snackbar: true,
            snackbarText: "Email dan Token yang anda masukkan salah !",
          });
        }
      }
    }
  }
  
  render() {
    const { classes } = this.props;
    const {
      email,
      token,
      fetching,
      success,
      session_id,
      snackbar,
      snackbarText,
      result,
    } = this.state;

    if (success && result)
      return (
        <Redirect
          to={{
            pathname: "/premium",
            state: { auth: true },
          }}
        />
      );
    else if (success && !result) {
      return (
        <Redirect
          to={{
            pathname: "/quiz",
            state: { auth: true },
          }}
        />
      );
    }
    return (
      <>
        <Navbar loginProp={{ isLogin: false }} />
        <div style={{ maxWidth: window.innerWidth > 700 ? 1140 : "100%" ,margin:'0 auto'}}>
          <FormLogin onLogin={(input)=>{
           
            this.props.doLogin({
              email: input.email,
              password: input.token
            });
          }}/>
        </div>
        <Footer/>
        <Snackbar
          open={snackbar}
          message={snackbarText}
          autoHideDuration={6000}
        ></Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  doLogin: (data) => dispatch(LoginAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginContainerStyles)(LoginContainer));
