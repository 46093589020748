import React from "react";
import {
  Grid,
  CircularProgress,
  Slider,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import Footer from "../Footer";
import YouTube from "react-youtube";
import loginAsGuestAction from "../../redux/actions/loginAsGuestAction";

import CallAction from "../../redux/actions/CallAction";
import Ranking from "./ranking";

import styled from "styled-components";
import Navbar from "../parts/Navbar";
import { Look211pngImage, group16Image } from "../parts/images";
import ContentResult from "../parts/ContentResult";
import FormPremiumAccount from "../parts/FormPremiumAccount";
import HeaderProfile from "../parts/HeaderProfile";
import YourProfile from "../parts/YourProfile";
import Characteristic from "../parts/Characteristic";
import StrengthProfile from "../parts/StrengthProfile";
import SelfLeadership from "../parts/SelfLeadership";
import PrimaryProfile from "../parts/PrimaryProfile";
import ProfileSupport from "../parts/ProfileSupport";
import ConflictProfile from "../parts/ConflictProfile";
import ProfileValues from "../parts/ProfileValues";
import ProfileInstinct from "../parts/ProfileInstinct";
import RoleModel from "../parts/RoleModel";
import IndustryRoleModel from "../parts/IndustryRoleModel";
import Elements from "../parts/Elements";

const Prof = styled.div`
  @font-face {
    font-family: "Poppins Regular";
    src: local("Poppins Regular"),
      url(../../assets/fonts/Poppins-Regular.ttf) format("truetype");
  }
  font-family: "Poppins Regular";
`;

const _ = require("lodash");
const defaults = [
  {
    subject: "Wizard",
    score: 0,
    fullMark: 150,
    name: "creator",
  },
  {
    subject: "Bard",
    score: 0,
    fullMark: 150,
    name: "star",
  },
  {
    subject: "Knight",
    score: 0,
    fullMark: 150,
    name: "supporter",
  },
  {
    subject: "Arbiter",
    score: 0,
    fullMark: 150,
    name: "dealmaker",
  },
  {
    subject: "Merchant",
    score: 0,
    fullMark: 150,
    name: "trader",
  },
  {
    subject: "Priest",
    score: 0,
    fullMark: 150,
    name: "accumulator",
  },
  {
    subject: "Marshal",
    score: 0,
    fullMark: 150,
    name: "lord",
  },
  {
    subject: "Alchemist",
    score: 0,
    fullMark: 150,
    name: "mechanic",
  },
];

const TextLabel = (props) => {
  const { name, text } = props;
  return (
    <div>
      <h4
        style={{
          fontSize: "120%",
          margin: 0,
          display: "inline-block",
          marginRight: 10,
        }}
      >
        {name}
      </h4>
      <span style={{ fontSize: "120%" }}>{text}</span>
    </div>
  );
};

const Row = styled.div`
  .picture {
    margin-left: 20px;
    width: 80%;
    heigth: 80%;
  }
  .text {
    margin-top: 40px;
  }
  p {
    font-size: 17px;
    display: inline;
    @font-face {
      font-family: "Poppins SemiBold";
      src: local("Poppins SemiBold"),
        url(../../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
    }
    font-family: "Poppins SemiBold";
  }
  @media screen and (max-width: 420px) {
    .picture {
      margin-left: 10px;
      position: absolute;
      width: 30%;
      height: auto;
    }
    .text {
      margin-top: 10px;
      margin-left: 150px;
      font-size: 7px;
    }
  }
  .judul {
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  .title {
    display: flex;
    flex-wrap: nowrap;
  }
`;

const Circle = styled.div`
  margin-left: 20px;
  margin-top: -10px;
  width: 40px;
  height: 40px;
  background: #8ebc50;
  border-radius: 100%;
`;
const Premium = styled.div`
  @font-face {
    font-family: "Poppins Regular";
    src: local("Poppins Regular"),
      url(../../assets/fonts/Poppins-Regular.ttf) format("truetype");
  }
  font-family: "Poppins Regular";
  .point {
    margin-top: 140px;
  }
  @media screen and (max-width: 420px) {
    .point {
      margin-top: -30px;
    }
    .pict {
      display: none;
    }
    .text-dark {
      margin-top: 10px;
      text-align: center;
    }
    h5,
    h2 {
      font-size: 15px;
    }
  }
`;

const Header = styled.section`
  background-image: url("${group16Image.url}");
  background-repeat: no-repeat;
  @font-face {
    font-family: "Poppins Black";
    src: local("Poppins Black"),
      url(../../assets/fonts/Poppins-Black.ttf) format("truetype");
  }
  font-family: "Poppins Black";
  h3.title {
    padding-top: 100px;
    font-size: 30px;
    text-align: center;
  }
  @media screen and (max-width: 420px) {
    h3.title {
      padding-top: 30px;
      font-size: 20px;
      text-align: center;
    }
  }
`;
class PremiumProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      result_data: [],
      max: 100,
      page: 2,
      content: null,
      new_quiz: false,
    };
    this.accessDenied = this.accessDenied.bind(this);
    this.populateResult = this.populateResult.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.initHomeContent = this.initHomeContent.bind(this);
  }

  componentDidMount() {
    const { callAction, call_action, slug } = this.props;

    let token = localStorage.getItem("token");
    /*
    callAction(call_action, "home", {
      endpoint: "/page/content/home",
      scenario: "get",
    });*/
    callAction(call_action, "me", {
      endpoint: "/me",
      scenario: "get",
    });
    if (typeof token === "undefined") {
      this.props.history.push("login");
    } else {
      //ask for new token
      this.props.loginAsGuest();
    }
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action !== this.props.call_action) {
      if (typeof call_action.profile !== "undefined") {
        if (call_action.profile !== null) {
          this.populateResult(call_action.profile.result);
        }
      }
    }
    if (call_action.home !== _props.call_action.home) {
      this.initHomeContent(call_action.home);
    }
    if (call_action.me !== _props.call_action.me) {
      this.checkUser(call_action.me);
    }
  }
  populateResult(data) {
    let fullscore = 0;
    try {
      for (var k in data) {
        if (k !== "created_at" && k !== "account_id" && k !== "id") {
          fullscore = data[k] > fullscore ? data[k] : fullscore;
        }
      }
      let d = [];
      for (let i = 0; i < defaults.length; i++) {
        d[i] = defaults[i];
        d[i].score = data[defaults[i].name];
        d[i].fullMark = fullscore;
      }

      this.setState({
        result_data: d,
        max: fullscore,
      });
    } catch (err) {
      console.log(err);
    }
  }
  initHomeContent(payload) {
    const { call_action, callAction } = this.props;
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    callAction(
      call_action,
      "profile",
      {
        endpoint: "/premium/latest",
      },
      true
    );

    this.setState({
      content: payload,
    });
  }
  checkUser(payload) {
    const { call_action, callAction } = this.props;
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    if (!payload.flag_quiz) {
      document.location = "/quiz";
    } else {
      callAction(call_action, "home", {
        endpoint: "/page/content/home",
        scenario: "get",
      });
    }
  }
  accessDenied() {
    const { classes, call_action, history } = this.props;

    return (
      <div>
        <Navbar
          loginProp={{ isLogin: localStorage.getItem("token") ? true : false }}
        />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid
            container
            style={{
              maxWidth: 800,
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid item xs={12} md={12}>
              <div style={{ textAlign: "center", height: window.innerHeight }}>
                <h1 style={{}}>ACCESS DENIED</h1>
                <h3 style={{ margin: 0, marginBottom: 20 }}>
                  Mohon maaf, akun anda tidak memiliki izin untuk mengakses
                  halaman ini!
                  <br />
                  Jika anda merasa telah mendapatkan Token dari kami dan tidak
                  dapat mengakses halaman ini. Silahkan hubungi Customer Service
                  kami di Whatsapp: +62-822-9999-5232.
                </h3>
                <div
                  style={{
                    textAlign: "center",
                    margin: 30,
                  }}
                >
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    LOGIN ULANG
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }

  renderContent() {
    const { classes, call_action, history } = this.props;
    const { result_data, max } = this.state;
    if (typeof call_action.profile === "undefined") return null;
    let payload = call_action.profile;
    if (typeof payload === "undefined") return null;
    if (payload === null) return null;
    if (payload.status === 0) return this.accessDenied();
    if (typeof payload.content === "undefined") return null;

    let { content, result } = payload;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return (
      <Prof>
        <Navbar loginProp={{ isLogin: true }} />
        <HeaderProfile content={content} />
        <YourProfile content={content} result_data={result_data} max={max} />
        <Characteristic content={content} />
        <StrengthProfile content={content} />
        <SelfLeadership content={content} />
        <PrimaryProfile content={content} />
        <ProfileSupport content={content} />
        <ConflictProfile content={content} />
        <ProfileValues content={content} />
        <ProfileInstinct content={content} />
        <RoleModel content={content} />
        <IndustryRoleModel content={content} />
        <Elements />
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div>
                <h3>Apakah hasil tes Shiokaya saya ini permanen? Apakah mungkin akan berubah seiring dengan jalannya waktu?</h3>
                <p>
                  Shiokaya adalah bagian dari diri dan karakteristik kita yang
                  sifatnya kontinum, dinamis, dan progresif. Setelah kita
                  menyadari, menerima, dan menggunakan pengetahuan shiokaya diri
                  kita, maka dalam jangka waktu tertentu (bulan, tahun), bisa
                  jadi terjadi penyesuaian dan perubahan atas kondisi shiokaya
                  kita saat ini.
                </p>
                <p>
                  Seringkali perubahan itu sifatnya tidak signifikan, tetapi
                  tetap merupakan hal yang menyenangkan jika kita ingin
                  mengetahui posisi shiokaya kita yang baru.
                </p>
                <p>Untuk menghargai para mitra shiokaya yang ingin mengetahui posisi baru dirinya, dan bisa beradaptasi atas kekuatan dan tantangan baru yang mungkin dihadapi, kami memberikan kemudahan, untuk bisa melakukan test shiokaya kembali dengan harga mitra (50% off), dengan akun yang sama ketika anda mendaftarkan akun sebelumnya.</p>
                <div style={{textAlign:'center'}}>
                <button
                  class="button bg-purple btn-round btn-lg"
                  onClick={() => {
                    document.location = "/buy";
                  }}
                >
                  UPDATE PROFIL SHIOKAYA
                </button></div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Prof>
    );
  }

  render() {
    const { classes, call_action, history } = this.props;
    const { result_data, max } = this.state;

    if (typeof call_action.profile === "undefined") return null;
    let payload = call_action.profile;
    if (typeof payload === "undefined") return null;
    if (payload === null) return null;
    if (payload.status === 0) return this.accessDenied();
    if (typeof payload.content === "undefined") return null;

    let { content, result } = payload;
    if (this.state.page === 2) {
      return this.renderContent();
    }

    if (this.state.new_quiz)
      return (
        <Premium>
          <Navbar
            loginProp={{
              isLogin: localStorage.getItem("token") ? true : false,
            }}
          />
          <section className="container bg-white text-dark"></section>
          <section className="container bg-white">
            <div className="row clearfix">
              <div className="col-lg-9 col-md-9 col-sm-9 my-auto">
                <h2 className="text-dark">
                  {this.state.content.blocks["intro-premium"].title}
                </h2>
                <br />
                <h5 className="alert alert-danger">
                  INGIN LEBIH TAHU TENTANG PROFIL SHIOKAYA ANDA?
                </h5>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="align-center mt-5">
                  <button
                    className="btn btn-round btn-lg bg-purple"
                    onClick={() => {
                      this.setState({ page: 2 });
                    }}
                  >
                    MULAI!
                  </button>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </Premium>
      );
    return (
      <Premium>
        <Navbar
          loginProp={{ isLogin: localStorage.getItem("token") ? true : false }}
        />
        <section className="container bg-white text-dark"></section>
        <section className="container bg-white">
          <div className="row clearfix">
            <div className="col-lg-9 col-md-9 col-sm-9 my-auto">
              <h2 className="text-dark">
                {this.state.content.blocks["intro-premium"].title}
              </h2>
              <br />
              <h5 className="alert alert-danger">
                LAPORAN LENGKAP PROFIL SHIOKAYA PREMIUM ANDA TELAH SIAP!
              </h5>
            </div>
            <br />
            <div className="col-lg-3 col-md-3 col-sm-3 mb-2">
              <img
                src={Look211pngImage.url}
                alt={Look211pngImage.alt}
                className="pict"
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div
                style={{ color: "#000" }}
                dangerouslySetInnerHTML={{
                  __html: this.state.content.blocks["intro-premium"].content,
                }}
              />
              <div>
                <br />
                <img src="ttd.png" style={{ width: 200 }} />
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="align-center mt-5">
                <button
                  className="btn btn-round btn-lg bg-purple"
                  onClick={() => {
                    this.setState({ page: 2 });
                  }}
                >
                  LANJUT KE PROFIL ANDA
                </button>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Premium>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action, guestLogin } = state;
  return {
    call_action,
    guestLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginAsGuest: (data) => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(PremiumProfileContainer));
