import React from "react";
import PoppinsMedium from "../../../assets/fonts/Poppins-Medium.ttf";
import { greenBackgroundImage, buttonImage } from "../images";
import styled from "styled-components";

const Content = styled.section`
  background-image: url("${greenBackgroundImage.url}");
  background-size: 130% 170%;
  background-position: top;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  text-align: left;
  padding: 30px;
  @font-face {
    font-family: "Poppins MediumItalic";
    src: local("${PoppinsMedium}"), url("${PoppinsMedium}") format("truetype");
  }
  font-family: "Poppins MediumItalic";
  .title {
    padding-top: 20px;
    font-size: 25px;
    margin-right: 25px;
  }
  .button {
    background-image: url("${buttonImage.url}");
    background-size: 100% 200%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5px 70px 5px 20px;
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    background-size: 120% 180%;
    .title {
      font-size: 13px;
    }
    .button {
      background-size: 100% 150%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 5px 45px 5px 10px;
      font-size: 12px;
    }
  }
`;

function Test({buylink}) {
  return (
    <section className="container">
      <div className="row clearfix align-center">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Content>
            <p>
            Sisihkan waktu selama 5 menit, isi pertanyaan singkat shiokaya untuk dapat  gambaran detail tentang potensi alami keberhasilan diri anda.
            </p>
            <p>
              <strong>Shiokaya</strong> merupakan alat ukur potensi model keberhasilan diri kita, yang menghasilkan informasi tentang bagaimana anda bisa menggunakan potensi yang selama ini ada di dalam diri kita, untuk bisa mencapai keberhasilan lebih cepat dan akurat.
            </p>
            <p>
            3 hal yang bisa anda lakukan setelah mendapatkan gambaran melalui shiokaya:
            </p>
            <ol
              style={{
                padding: 0,
                paddingLeft: 15,
              }}
            >
              <li>Mampu mengetahui masala sebenarnya yang menahan kamu untuk sukses </li>
              <li>Menyadari dan bisa memanfaatkan kekuatan alami diri kamu</li>
              <li>
              Mengetahui tipe mitra atau karyawan yang kamu butuhkan untuk berhasil lebih cepat
              </li>
              
            </ol>
            <p>
            Gabungan dari ketiganya, bikin kamu bisa lebih cepat, lebih hemat tenaga, dan lebih hemat biaya dalam mencapai target dan keberhasilan kamu. 
            </p>
            <div style={{ padding: 10, textAlign: "center" }}>
              <button
                className="button bg-purple btn-round btn-lg"
                onClick={() => {
                  document.location = buylink;
                }}
              >
                Ikuti Test
              </button>
            </div>
          </Content>
        </div>
      </div>
    </section>
  );
}

export default Test;
