import Api from "../../Services/Api";

const api = new Api();

export default data => dispatch => {
  dispatch({
    type: "REGISTER",
    data,
    payload: null
  });

  api.register(data).then(response => {
    if (typeof response.status !== "undefined") {
        
      const { data } = response;
      dispatch({
        type: "REGISTER_SUCCESS",
        payload: response,
        message: ""
      });

    } else {
      dispatch({
        type: "REGISTER_ERROR",
        payload: null,
        message: "Tidak berhasil mengirim data. Silahkan coba kembali !"
      });
    }
  });
};
