import React from "react";
import CardProfileSupport from "../CardProfileSupport";
import styled from "styled-components";

const ProfSupp = styled.section`
.jumbotron {
  background-color: #F8F8F5;
}

h4 {
  margin-top:0;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}

span {
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}

p {
  
  font-family: "Poppins";
  font-size: 100%;
}

@media screen and (max-width: 420px) {
  h4 {
    margin-top:0;
  }
}
`;

function ProfileSupport(props) {
  const {content} = props;
  return (
    <ProfSupp className="container">
      <div className="jumbotron">
      <h4 className="title text-center">
        <strong>Profil yang bisa memberikan dukungan paling besar</strong>
      </h4>
      <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.mitra,
                      }}
                    />
      <p>
        <h4>Primer</h4>
      </p>
      <CardProfileSupport
       
        colSatu="Mitra"
        colDua="Kekuatan"
        colTiga="Dukugan yang diperoleh"
      />
      {content.dukungan_primer.map((row,index) => (
           <CardProfileSupport
           key={index}
           colSatu={row.title}
           colDua={row.kekuatan}
           colTiga={row.dukungan}
         />
      ))}
     
     
      <p>
        <h4>Sekunder</h4>
      </p>
      <CardProfileSupport
        colSatu="Mitra"
        colDua="Kekuatan"
        colTiga="Dukugan yang diperoleh"
      />
      {content.dukungan_sekunder.map((row,index) => (
           <CardProfileSupport
           key={index}
           colSatu={row.title}
           colDua={row.kekuatan}
           colTiga={row.dukungan}
         />
      ))}
      </div>
    </ProfSupp>
  );
}

export default ProfileSupport;