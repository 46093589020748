import logoUrl from "../../../GlobalAsset/logo.png";
import background from "../../../GlobalAsset/BackgroundHeaderHome.png";
import imageLogin from "../../../GlobalAsset/Login.png";
import backgroundBaseLogin from "../../../GlobalAsset/BackgroundLogin.png";
import wrapper from "../../../GlobalAsset/Group 2.png";
import instagramIcons from "../../../GlobalAsset/instagram-sketched.png";
import waIcons from "../../../GlobalAsset/whatsapp.png";
import emailIcons from "../../../GlobalAsset/email-3.png";
import backgorundTestimoni from "../../../GlobalAsset/Review-02.png";
import trustOne from "../../../GlobalAsset/Features-04.png";
import trustTwo from "../../../GlobalAsset/Features-03.png";
import trustThree from "../../../GlobalAsset/Features-05.png";
import greenBackground from "../../../GlobalAsset/Group 11.jpg";
import button from "../../../GlobalAsset/Group 9.png";
import helloPng from "../../../GlobalAsset/Hello-09.png";
import group16 from "../../../GlobalAsset/Group 16.png";
import pathCopy16 from "../../../GlobalAsset/Path Copy.png";
import path from "../../../GlobalAsset/Path.png";
import group32 from "../../../GlobalAsset/Group 32.jpg";
import group33 from "../../../GlobalAsset/Group 33.jpg";
import group29 from "../../../GlobalAsset/Group 29.jpg";
import group31 from "../../../GlobalAsset/Group 31.jpg";
import group9 from "../../../GlobalAsset/Group 9.png";
import checkList07 from "../../../GlobalAsset/Check List-07.png";
import containerCarousel from "../../../GlobalAsset/Rectangle 2.png";
import look10png from "../../../GlobalAsset/Look-10.png";
import backgroundLayerCopy from "../../../GlobalAsset/bgc.png";
import Group2copying from "../../../GlobalAsset/Group 2 copying.png";
import Pathcopyagainpng from "../../../GlobalAsset/Path copyagain.png";
import priest from "../../../GlobalAsset/priest.png";
import Group3png from "../../../GlobalAsset/Group 3.png";
import PathKopi from "../../../GlobalAsset/Path Kopi.png";
import Group2Copy from "../../../GlobalAsset/Group 2 Copy.png";
import marshal3 from "../../../GlobalAsset/marshal3.png";
import PathCopy2 from "../../../GlobalAsset/Path Copy 2.png";
import Rectanglejpg from "../../../GlobalAsset/Rectangle.jpg";
import profile1 from "../../../GlobalAsset/profile1.png";
import profile2 from "../../../GlobalAsset/profile2.png";
import profile3 from "../../../GlobalAsset/profile3.png";
import BackgroundLayerCopy1 from "../../../GlobalAsset/bg1.png";
import oval from "../../../GlobalAsset/Oval.png";
import Look211png from "../../../GlobalAsset/Look 2-11.png";
import group9copypng from "../../../GlobalAsset/Group 9 copy.png";
import BackgroundLayerCopyd from "../../../GlobalAsset/bgcd.png";
import OvalCopypng from "../../../GlobalAsset/Oval Copy.png";
import star from "../../../GlobalAsset/Star.png";
import diagram from "../../../GlobalAsset/diagram.png";
import shio4 from "../../../GlobalAsset/shio4.png";
import BackgroundLayerCopycopypng from "../../../GlobalAsset/bgcc.png";
import sio2 from "../../../GlobalAsset/sio2.png";
import sio3 from "../../../GlobalAsset/sio3.png";
import sio from "../../../GlobalAsset/sio.png";
import BackgroundLayerCopycopy1png from "../../../GlobalAsset/bgcc1.png";
import RectangleCopy13jpg from "../../../GlobalAsset/Rectangle Copy 13.jpg";
import RectangleCopy73jpg from "../../../GlobalAsset/Rectangle Copy 73.jpg";
import Group3jpg from "../../../GlobalAsset/Group 3.jpg";
import Happy06png from "../../../GlobalAsset/Happy-06.png";
import prev from "../../../GlobalAsset/prev.png";
import next from "../../../GlobalAsset/next.png";

export const prevImage = {
  url: prev,
  alt: "prev image button",
};

export const nextImage = {
  url: next,
  alt: "next image button",
};

export const Happy06pngImage = {
  url: Happy06png,
  alt: "Happy 06 png image",
};

export const Group3jpgImage = {
  url: Group3jpg,
  alt: "background register button",
};

export const RectangleCopy73jpgImage = {
  url: RectangleCopy73jpg,
  alt: "Rectangle Copy 73 jpg",
};

export const RectangleCopy13jpgImage = {
  url: RectangleCopy13jpg,
  alt: "Rectang leCopy 13 jpg",
};

export const BackgroundLayerCopycopy1pngImage = {
  url: BackgroundLayerCopycopy1png,
  alt: "Background Layer Copy copy1 png",
};

export const sioImage = {
  url: sio,
  alt: "sio image",
};

export const sio3Image = {
  url: sio3,
  alt: "sio 3 image",
};

export const shio2Image = {
  url: sio2,
  alt: "sio 2 image",
};

export const BackgroundLayerCopycopypngImage = {
  url: BackgroundLayerCopycopypng,
  alt: "Background Layer Copy copy png",
};

export const shio4Image = {
  url: shio4,
  alt: "shio 4 png",
};

export const diagramImage = {
  url: diagram,
  alt: "diagram image",
};

export const starImage = {
  url: star,
  alt: "star image",
};

export const OvalCopypngImage = {
  url: OvalCopypng,
  alt: "Oval Copy png",
};

export const BackgroundLayerCopydImage = {
  url: BackgroundLayerCopyd,
  alt: "Background Layer Copyd png",
};

export const group9copypngImage = {
  url: group9copypng,
  alt: "group 9 copy png",
};

export const Look211pngImage = {
  url: Look211png,
  alt: "Look 2-11 png",
};

export const ovalPng = {
  url: oval,
  alt: "oval png",
};

export const BackgroundLayerCopy1Image = {
  url: BackgroundLayerCopy1,
  alt: "Background Layer Copy png",
};

export const profile1Image = {
  url: profile1,
  alt: "profile 1 png",
};

export const profile2Image = {
  url: profile2,
  alt: "profile 2 png",
};

export const profile3Image = {
  url: profile3,
  alt: "profile 3 png",
};

export const RectanglejpgImage = {
  url: Rectanglejpg,
  alt: "Rectangle jpg image",
};

export const PathCopy2Image = {
  url: PathCopy2,
  alt: "Path Copy 2 png",
};

export const marshal3Image = {
  url: marshal3,
  alt: "marshal 3 png",
};

export const Group2CopyImage = {
  url: Group2Copy,
  alt: "Group 2 Copy png",
};

export const PathKopiimage = {
  url: PathKopi,
  alt: "Path Kopi png",
};

export const Group3pngImage = {
  url: Group3png,
  alt: "Group 3 png",
};

export const priestImage = {
  url: priest,
  alt: "priest image jpg",
};

export const PathcopyagainpngImage = {
  url: Pathcopyagainpng,
  alt: "Path copya gain png",
};

export const Group2copyingImage = {
  url: Group2copying,
  alt: "Group 2 copying png",
};

export const BackgroundLayerCopyImage = {
  url: backgroundLayerCopy,
  alt: "background Layer Copy png",
};

export const look10pngImage = {
  url: look10png,
  alt: "look 10 png",
};

export const containerCarouselBackground = {
  url: containerCarousel,
  alt: "container carousel image",
};

export const logo = {
  url: logoUrl,
  alt: "Logo Shio Kaya",
};

export const backgorundColor = {
  url: background,
  alt: "back ground base color home",
};

export const backgroundLogin = {
  url: imageLogin,
  alt: "login background",
};

export const backgroundLoginBase = {
  url: backgroundBaseLogin,
  alt: "background base Login",
};

export const loginWrapper = {
  url: wrapper,
  alt: "wrapper login footer",
};

export const instagramicon = {
  url: instagramIcons,
  alt: "instagram icons",
};

export const waIcon = {
  url: waIcons,
  alt: "whatsapp icons",
};

export const emailIcon = {
  url: emailIcons,
  alt: "email icons",
};

export const backgroundTestimonial = {
  url: backgorundTestimoni,
  alt: "back ground testimoni",
};

export const trustImageOne = {
  url: trustOne,
  alt: "trus one image",
};

export const trustImageTwo = {
  url: trustTwo,
  alt: "trus two image",
};

export const trustImageThree = {
  url: trustThree,
  alt: "trus three image",
};

export const greenBackgroundImage = {
  url: greenBackground,
  alt: "green backgorund",
};

export const buttonImage = {
  url: button,
  alt: "button",
};

export const helloPngImage = {
  url: helloPng,
  alt: "hello png image",
};

export const group16Image = {
  url: group16,
  alt: "group16 Image",
};

export const pathCopy16Image = {
  url: pathCopy16,
  alt: "pathCopy16 image",
};

export const pathImage = {
  url: path,
  alt: "path",
};

export const gorup32Image = {
  url: group32,
  alt: "group 32 image",
};

export const gorup33Image = {
  url: group33,
  alt: "group 33 image",
};

export const gorup29Image = {
  url: group29,
  alt: "group 29 image",
};

export const gorup31Image = {
  url: group31,
  alt: "group 31 image",
};

export const group9Image = {
  url: group9,
  alt: "group 9 image",
};

export const checkList07Image = {
  url: checkList07,
  alt: "check List 07 image",
};
