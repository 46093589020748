import React from "react";
import CardChar from "../CardChar";
import styled from "styled-components";

const Prime = styled.section`
.jumbotron {
  background-color: #F8F8F5;
}
h4 {
  margin-top:0;
  @font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins ExtraBold"),
      url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  font-family: "Poppins ExtraBold";
}

span {
  font-family: "Poppins ExtraBold";
}

p {
  
  font-family: "Poppins";
  font-size: 100%;
}

@media screen and (max-width: 420px) {
  h4 {
    margin-top:0;
  }
  h4.subtitle{
    text-align:center;
  }
}
`;

function PrimaryProfile(props) {
  const {content} = props;
  return (
    <Prime className="container">
      <div className="jumbotron">
        <h4 className="text-center">Profil Utama dan Profil Bayangan</h4>
      
        <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content.profil_utama_bayangan,
                    }}
                  />
                  <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content[content.content.slug + "_ub"],
                    }}
                  />
        <p>
          <h4 class="subtitle">{content.content.title}</h4>
        </p>
        <CardChar
          title="Karakter Alami"
          text={content.content.karakter_alami}
        />
        <br />
        <CardChar
          title="Faktor Kesuksesan"
          text={content.content.faktor_kesuksesan}
        />
        <br />
        <CardChar
          title="Faktor Kegagalan"
          text={content.content.faktor_kegagalan}
        />
        <p>
          <h4 class="subtitle">{content.bayangan1.title}</h4>
        </p>
        <CardChar
          title="Karakter Alami"
          text={content.bayangan1.karakter_alami}
        />
        <br />
        <CardChar
          title="Faktor Kesuksesan"
          text={content.bayangan1.faktor_kesuksesan}
        />
        <br />
        <CardChar
          title="Faktor Kegagalan"
          text={content.bayangan1.faktor_kegagalan}
        />
        <p>
          <h4 class="subtitle">{content.bayangan2.title}</h4>
        </p>
        <CardChar
          title="Karakter Alami"
          text={content.bayangan2.karakter_alami}
        />
        <br />
        <CardChar
          title="Faktor Kesuksesan"
          text={content.bayangan2.faktor_kesuksesan}
        />
        <br />
        <CardChar
          title="Faktor Kegagalan"
          text={content.bayangan2.faktor_kegagalan}
        />
      </div>
    </Prime>
  );
}

export default PrimaryProfile;
