import Api from "../../Services/Api"; 

const api = new Api();

export default (data) => dispatch => {
    
    dispatch({
        type: 'GET_RESULT',
        data
    })
    
    api.call({
        endpoint:'/result',
        data
    },true).then((response)=>{
       
        if(typeof response.status !== 'undefined'){
            
            dispatch({
                type:"GET_RESULT_SUCCESS",
                payload: response
            })
        }else{
            dispatch({
                type:"GET_RESULT_ERROR",
                message: response.error
            })
        }
        
    })
  
}