
const styles = theme => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column"
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    action: {
      textAlign: "right"
    },
    topMargin: {
      marginTop: "30px"
    },
    filterButton: {
      marginTop: "20px"
    },
    logo: {
      width: "150px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "120px"
    },
    loginForm: {
      marginTop: 0,
      maxWidth: 400
    },
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(5)
    },
    action: {
      textAlign: "center"
    }
  });

  export default styles;