import React from "react";
import styled from "styled-components";
import ProfileResult from "../ProfileResult";

import { group9copypngImage, priestImage, loginWrapper } from "../images";

const BgWrapper = styled.section`
  position: relative;
  background-image: url("${group9copypngImage.url}");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -108px;
  padding-top: 200px;
  h3,
  p.mt-4 {
    @font-face {
      font-family: "Poppins ExtraBold";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
    }
    font-family: "Poppins ExtraBold";
  }
  p {
    @font-face {
      font-family: "Poppins Medium";
      src: local("Poppins ExtraBold"),
        url(../../assets/fonts/Poppins-Medium.ttf) format("truetype");
    }
    font-size: 15px;
    font-family: "Poppins Medium";
  }
  img.char {
    margin-top: 30px;
    margin-left: 190px;
    background-color: white;
    padding: 100px 55px 80px 60px;
    height: 35rem;
    width: auto;
    box-shadow: 3px 11px 35px -10px rgba(0,0,0,0.75);
-webkit-box-shadow: 3px 11px 35px -10px rgba(0,0,0,0.75);
-moz-box-shadow: 3px 11px 35px -10px rgba(0,0,0,0.75);
  
  }
  img.backgroundChar {
    margin-top: -55px;
    float: right;
    width: 50%;
    height: auto;
    z-index: -1;
  }

  @media screen and (max-width: 420px) {
    margin-top: 0px;
    padding-top: 30px;
    h3,
    p.mt-4 {
      font-size: 150%;
      text-align: center;
    }
    p {
      font-size: 100%;
    }
    background-image: none;
    img.backgroundChar {
      display: none;
    }
    img.char {
      margin: auto;
      padding: 0px 0px 0px 0px;
      box-shadow: none;
      height: 20rem;
      width: auto;
      display: flex;
      align-content: center;
    }
  }
`;

function HeaderProfile(props) {
  const { content } = props;
  const { title, photo_url } = content.content;
  return (
    <BgWrapper className="jumbotron bg-white text-dark">
      <div
        style={{
          maxWidth: window.innerWidth > 700 ? 1140 : "100%",
          margin: "0 auto",
        }}
      >
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <h3 style={{marginBottom:0,textAlign:'center'}}>
              {title.toUpperCase()}
            </h3>
            <ProfileResult content={content} />
            <p className="mt-4">
              <strong>Karakter Umum</strong>
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: content.content.karakter,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: content.content.summary,
              }}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src={photo_url} alt={"character"} className="char" />
            <img
              src={loginWrapper.url}
              alt={loginWrapper.alt}
              className="backgroundChar"
            />
          </div>
        </div>
      </div>
    </BgWrapper>
  );
}

export default HeaderProfile;
