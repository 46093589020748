import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { backgorundColor, buttonImage } from "../images";

import { Link } from "react-router-dom";

import PoppinsMedium from "../../../assets/fonts/Poppins-Medium.ttf";

const Header = styled.section`
  @font-face {
    font-family: "Poppins MediumItalic";
    src: local("${PoppinsMedium}"),
      url("${PoppinsMedium}") format("truetype");
  }
  font-family: 'Poppins MediumItalic';
 
  padding-bottom
  margin: center;
  .button {
    margin-left: -50px;
  }
  .text {
    margin-top: 20px;
    color: white;
  }
  h5.quote {
    font-size: 18px;
  }
  img {
    size : 100%;
    position: absolute;
    z-index: -1;
  }

  @media screen and (max-width: 420px) {
    background-image: url("${backgorundColor.url}");
    background-size: 100% 30%;
  }
`;

const Player = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 420px) {
    width: auto;
    heigth: 100px;
  }
`;

const Text = styled.div`
  margin-top: 185px;
  p {
    font-size: 36px;
  }
  @media screen and (max-width: 420px) {
    p {
      margin-top: -70px;
      font-size: 13px;
    }
  }
`;

function Heading() {
  return (
    <Header className="container">
      <div className="row clearfix" style={{ marginTop: "-108px" }}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <Text className="text-center" style={{paddingBottom:60}}>
            <p style={{
              fontSize:'170%'
            }}>
              <strong>
               
                Rahasia mengenali potensi diri, berbisnis dan berpartner, untuk sukses dan kaya yang hemat waktu hemat tenaga, dan hemat biaya
              </strong>
            </p>
          </Text>
        </div>
        <div className="col-lg-12 col-md-12">
          <Player>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=B9Wz-RwYr_Y"
            />
          </Player>
        </div>
        <div className="col-lg-12 col-md-12 text-center">
          <div className="card mt-5">
            <h5 className="quote">
              <strong style={{
                    paddingTop: 60,
                    paddingBottom: 60,
                    lineHeight: '1.8em'
              }}>
              Tes potensi model keberhasilan diri kamu, dan kamu langsung dapat penjelasan akurat tentang kekuatan kamu untuk bisa sukses, tantangan yang akan kamu hadapi dari diri dan sekeliling kamu, serta partner sepeti apa yang kamu butuhkan, untuk bisa sukses dan kaya dengan efektif dan efisien.
              </strong>
            </h5>
            {/*}
            <Link  onClick={()=>{
              document.location="/buy?affiliate_id=8";
            }}>
              <img
                src={buttonImage.url}
                alt={buttonImage.alt}
                width="150px"
                height="auto"
                className="button"
              />
              <p className="text">Ikuti Test</p>
          </Link>{*/}
          </div>
        </div>
      </div>
    </Header>
  );
}

export default Heading;
