import React from "react";
import {
  Grid,
  CircularProgress,
  Slider,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  Radar,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Legend,
} from "recharts";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import QuizContainerStyles from "./QuizContainerStyles";
import Header from "./Header";
import Footer from "../Footer";
import YouTube from "react-youtube";
import loginAsGuestAction from "../../redux/actions/loginAsGuestAction";

import CallAction from "../../redux/actions/CallAction";
import Ranking from "./ranking";

const _ = require("lodash");
const defaults = [
  {
    subject: "Wizard",
    score: 0,
    fullMark: 150,
    name: "creator",
  },
  {
    subject: "Bard",
    score: 0,
    fullMark: 150,
    name: "star",
  },
  {
    subject: "Knight",
    score: 0,
    fullMark: 150,
    name: "supporter",
  },
  {
    subject: "Arbiter",
    score: 0,
    fullMark: 150,
    name: "dealmaker",
  },
  {
    subject: "Merchant",
    score: 0,
    fullMark: 150,
    name: "trader",
  },
  {
    subject: "Priest",
    score: 0,
    fullMark: 150,
    name: "accumulator",
  },
  {
    subject: "Marshal",
    score: 0,
    fullMark: 150,
    name: "lord",
  },
  {
    subject: "Alchemist",
    score: 0,
    fullMark: 150,
    name: "mechanic",
  },
];

const TextLabel = (props) => {
  const { name, text } = props;
  return (
    <div>
      <h4
        style={{
          fontSize: "120%",
          margin: 0,
          display: "inline-block",
          marginRight: 10,
        }}
      >
        {name}
      </h4>
      <span style={{ fontSize: "120%" }}>{text}</span>
    </div>
  );
};

class PremiumProfileTestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      result_data: [],
      max: 100,
      page: 2,
    };
    this.accessDenied = this.accessDenied.bind(this);
    this.populateResult = this.populateResult.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount() {
    const { callAction, call_action, slug } = this.props;

    let token = localStorage.getItem("token");
    callAction(
      call_action,
      "profile",
      {
        endpoint: "/premium/latest",
      },
      true
    );
    if (typeof token === "undefined") {
      this.props.history.push("login");
    } else {
      //ask for new token
      this.props.loginAsGuest();
    }
  }
  componentDidUpdate(_props, _states) {
    const { call_action } = this.props;
    if (_props.call_action !== this.props.call_action) {
      if (typeof call_action.profile !== "undefined") {
        if (call_action.profile !== null) {
          console.log(call_action.profile.result);
          this.populateResult(call_action.profile.result);
        }
      }
    }
  }
  populateResult(data) {
    let fullscore = 0;

    for (var k in data) {
      if (k !== "created_at" && k !== "account_id" && k !== "id") {
        fullscore = data[k] > fullscore ? data[k] : fullscore;
      }
    }
    let d = [];
    for (let i = 0; i < defaults.length; i++) {
      d[i] = defaults[i];
      d[i].score = data[defaults[i].name];
      d[i].fullMark = fullscore;
    }

    this.setState({
      result_data: d,
      max: fullscore,
    });
  }

  accessDenied() {
    const { classes, call_action, history } = this.props;

    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid
            container
            style={{
              maxWidth: 800,
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid item xs={12} md={12}>
              <div style={{ textAlign: "center", height: window.innerHeight }}>
                <h1 style={{}}>ACCESS DENIED</h1>
                <h3 style={{ margin: 0, marginBottom: 20 }}>
                  Mohon maaf, akun anda tidak memiliki izin untuk mengakses
                  halaman ini!
                  <br />
                  Jika anda merasa telah mendapatkan Token dari kami dan tidak
                  dapat mengakses halaman ini. Silahkan hubungi Customer Service
                  kami di Whatsapp: +62-822-9999-5232.
                </h3>
                <div
                  style={{
                    textAlign: "center",
                    margin: 30,
                  }}
                >
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    LOGIN ULANG
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
  renderContent() {
    const { classes, call_action, history } = this.props;
    const { result_data, max } = this.state;
    if (typeof call_action.profile === "undefined") return null;
    let payload = call_action.profile;
    if (typeof payload === "undefined") return null;
    if (payload === null) return null;
    if (payload.status === 0) return this.accessDenied();
    if (typeof payload.content === "undefined") return null;

    let { content, result } = payload;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            webkitBoxSizing: "border-box" /* Safari/Chrome, other WebKit */,
            mozBoxSizing: "border-box" /* Firefox, other Gecko */,
            boxSizing: "border-box",
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid
            container
            style={{
              maxWidth: 800,
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid item xs={12} md={12}>
              <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0 }}>
                  {content.content.title.toUpperCase()}
                </h1>
                <h3 style={{ margin: 0 }}>({content.content.tagline})</h3>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <TextLabel
                    name={"Elemen"}
                    text={
                      content.content.nama + "(" + content.content.simbol + ")"
                    }
                  />
                </div>
                <div
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={content.content.logo_url}
                    style={{
                      width: 150,
                    }}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={content.content.photo_url}
                  style={{
                    width: window.innerWidth > 600 ? "50%" : 300,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <TextLabel name={"Warna"} text={content.content.warna} />
                <div
                  style={{
                    marginTop: 5,
                    width: "100%",
                    height: 30,
                    backgroundColor: content.content.color,
                    marginBottom: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                ></div>
                <div>
                  <p>{content.content.fungsi}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  borderTop: "1px solid #ccc",
                  padding: "10px 0px 0px 10px",
                }}
              >
                <div>
                  <h3>Karakter Umum</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.content.karakter,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.content.summary,
                    }}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <hr />
              <div
                style={{ margin: "0 auto", width: "100%", textAlign: "center" }}
              >
                <h1>PROFIL ANDA</h1>
                {result_data.length > 0 ? (
                  <RadarChart
                    key={Math.random() * 9999}
                    outerRadius={90}
                    width={300}
                    height={250}
                    data={result_data}
                    style={{ margin: "0 auto", textAlign: "center" }}
                  >
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis angle={30} domain={[0, max]} />
                    <Radar
                      name="Profile"
                      dataKey="score"
                      stroke={content.content.color}
                      fill={content.content.color}
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                ) : null}
                <hr />
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <div style={{ textAlign: "center", marginTop: 60 }}>
                  <span
                    style={{
                      color: content.content.color,
                      fontWeight: 700,
                      fontSize: "120%",
                      marginBottom: 20,
                    }}
                  >
                    {content.content.title.toUpperCase()}
                  </span>
                </div>
                <div
                  style={{ marginTop: 15 }}
                  dangerouslySetInnerHTML={{ __html: content.content.intro }}
                />
              </div>
              <div style={{ borderBottom: "1px solid #ccc", marginBottom: 30 }}>
                <div style={{ textAlign: "center", marginTop: 60 }}>
                  <span
                    style={{
                      color: content.content.color,
                      fontWeight: 700,
                      fontSize: "120%",
                    }}
                  >
                    KARAKTERISTIK
                  </span>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.content.deskripsi_karakter,
                  }}
                />
                <table
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: 100 }}>
                        <img
                          src={content.content.logo_url}
                          width="100"
                          height="100"
                        />
                      </td>
                      <td style={{ width: 497 }}>
                        <h4 style={{ color: content.content.color, margin: 0 }}>
                          Element: {content.content.simbol}
                        </h4>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: content.content.deskripsi_element,
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "100%",
                    height: "71px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ width: 100 }}>
                        <svg width="100" height="100">
                          <rect
                            width="100"
                            height="100"
                            style={{
                              fill: content.content.color,
                              strokeWidth: 1,
                              stroke: "#000000",
                            }}
                          />
                        </svg>
                      </td>
                      <td style={{ width: 497 }}>
                        <h4 style={{ color: content.content.color, margin: 0 }}>
                          Warna: {content.content.warna}
                        </h4>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: content.content.deskripsi_warna,
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      Kekuatan:{" "}
                      {content.content.kekuatan
                        .replace("<p>", "")
                        .replace("</p>", "")}
                    </h1>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <strong>Sisi Produktif</strong>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.content.sisi_produktif
                          .replace("<p>", "")
                          .replace("</p>", ""),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <strong>Sisi Tantangan</strong>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.content.sisi_tantangan
                          .replace("<p>", "")
                          .replace("</p>", ""),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div style={{ borderBottom: "1px solid #ccc", marginBottom: 30 }}>
                <div style={{ textAlign: "center", marginTop: 60 }}>
                  <span
                    style={{
                      color: content.content.color,
                      fontWeight: 700,
                      fontSize: "120%",
                      marginBottom: 10,
                    }}
                  >
                    SELF-LEADERSHIP
                  </span>
                </div>
                <div>
                  <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content.content.self_leadership,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 60,
                }}
              >
                <div style={{ textAlign: "center", marginTop: 60 }}>
                  <span
                    style={{
                      color: content.content.color,
                      fontWeight: 700,
                      fontSize: "120%",
                      marginBottom: 10,
                    }}
                  >
                    PROFIL UTAMA &amp; PROFIL BAYANGAN
                  </span>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content.profil_utama_bayangan,
                    }}
                  />
                  <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: content[content.content.slug + "_ub"],
                    }}
                  />
                  <p></p>
                  <p></p>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: content.content.color,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                >
                  <Grid item md={12}>
                    <h3 style={{ margin: 0, textAlign: "center" }}>
                      {content.content.title}
                    </h3>
                  </Grid>
                  <Grid item md={4}>
                    <h4>Karakter Alami</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.content.karakter_alami,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kesuksesan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.content.faktor_kesuksesan,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kegagalan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.content.faktor_kegagalan,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: content.bayangan1.color,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                >
                  <Grid item md={12}>
                    <h3 style={{ margin: 0, textAlign: "center" }}>
                      {content.bayangan1.title}
                    </h3>
                  </Grid>
                  <Grid item md={4}>
                    <h4>Karakter Alami</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan1.karakter_alami,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kesuksesan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan1.faktor_kesuksesan,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kegagalan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan1.faktor_kegagalan,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: content.bayangan2.color,
                    marginBottom: 20,
                    borderRadius: 10,
                  }}
                >
                  <Grid item md={12}>
                    <h3 style={{ margin: 0, textAlign: "center" }}>
                      {content.bayangan2.title}
                    </h3>
                  </Grid>
                  <Grid item md={4}>
                    <h4>Karakter Alami</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan2.karakter_alami,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kesuksesan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan2.faktor_kesuksesan,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <h4>Faktor Kegagalan</h4>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.bayangan2.faktor_kegagalan,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                    
                      PROFIL YANG BISA MEMBERIKAN DUKUNGAN PALING BESAR
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.mitra,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table
                      className={classes.table}
                      style={{
                        backgroundColor: "#ccccccs",
                      }}
                    >
                      <TableHead>
                        <TableRow
                          style={{
                            backgroundColor: "#000000",
                            color: "#ffffff",
                          }}
                        >
                          <TableCell style={{ color: "#ffffff" }}>
                            Mitra
                          </TableCell>
                          <TableCell align="left" style={{ color: "#ffffff" }}>
                            Kekuatan
                          </TableCell>
                          <TableCell align="left" style={{ color: "#ffffff" }}>
                            Dukungan yang Diperoleh
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <h3>PRIMER</h3>
                        {content.dukungan_primer.map((row) => (
                          <TableRow
                            key={row.slug}
                            style={{ backgroundColor: row.color }}
                          >
                            <TableCell component="th" scope="row">
                              {row.title}
                            </TableCell>
                            <TableCell align="left">{row.kekuatan}</TableCell>
                            <TableCell align="left">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row.dukungan,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        <h3>SEKUNDER</h3>
                        {content.dukungan_sekunder.map((row) => (
                          <TableRow
                            key={row.slug}
                            style={{ backgroundColor: row.color }}
                          >
                            <TableCell component="th" scope="row">
                              {row.title}
                            </TableCell>
                            <TableCell align="left">{row.kekuatan}</TableCell>
                            <TableCell align="left">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row.dukungan,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                     
                      KONFLIK ANTARA ORANG-ORANG YANG BERBEDA PROFIL
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.konflik,
                      }}
                    />
                  </Grid>
                  {content.konflik_primer.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          backgroundColor: item.color,
                          marginBottom: 10,
                          borderRadius: 10,
                        }}
                      >
                        <h3>{item.title}</h3>
                        <h4>Kekuatan: {item.kekuatan}</h4>
                        <div>
                          <span
                            style={{ color: "#ffffff;" }}
                            dangerouslySetInnerHTML={{
                              __html: content.primer,
                            }}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  {content.konflik_sekunder.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          backgroundColor: item.color,
                          marginBottom: 10,
                          borderRadius: 10,
                        }}
                      >
                        <h3>{item.title}</h3>
                        <h4>Kekuatan: {item.kekuatan}</h4>
                        <div>
                          <span
                            style={{ color: "#ffffff;" }}
                            dangerouslySetInnerHTML={{
                              __html: content.sekunder,
                            }}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  {content.konflik_tersier.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        style={{
                          backgroundColor: item.color,
                          marginBottom: 10,
                          borderRadius: 10,
                        }}
                      >
                        <h3>{item.title}</h3>
                        <h4>Kekuatan: {item.kekuatan}</h4>
                        <div>
                          <span
                            style={{ color: "#ffffff;" }}
                            dangerouslySetInnerHTML={{
                              __html: content.tersier,
                            }}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                     
                      NILAI (VALUE) UNTUK MENGGUNAKAN KEKUATAN DIRI ANDA
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.nilai,
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Inovasi</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.kekuatan.inovasi,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_kekuatan[0]} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Komunikasi</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.kekuatan.komunikasi,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_kekuatan[1]} />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Waktu</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.kekuatan.waktu,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_kekuatan[2]} />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Kesempurnaan</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.kekuatan.kesempurnaan,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_kekuatan[3]} />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                     
                      NALURI ALAMI ANDA DALAM PENCIPTAAN KEKAYAAN DAN KESUKSESAN
                    </h1>
                  </Grid>
                  <Grid item xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.naluri_copy,
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Influencing</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.naluri.influencing,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_naluri[0]} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Arranging</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.naluri.arranging,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_naluri[1]} />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Accumulating</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.naluri.accumulating,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_naluri[2]} />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <h3 style={{ margin: 0 }}>Integrating</h3>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <span
                      style={{ color: "#ffffff;" }}
                      dangerouslySetInnerHTML={{
                        __html: content.naluri.integrating,
                      }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Ranking value={content.nilai_naluri[3]} />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                      ROLE MODEL - TOKOH TERKENAL
                    </h1>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Alchemist</h2>

                    {content.shio_profesi.alchemist.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Wizard</h2>

                    {content.shio_profesi.wizard.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Bard</h2>

                    {content.shio_profesi.bard.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Knight</h2>

                    {content.shio_profesi.knight.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Arbiter</h2>

                    {content.shio_profesi.arbiter.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Merchant</h2>

                    {content.shio_profesi.merchant.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Priest</h2>

                    {content.shio_profesi.priest.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <h2 style={{ margin: 0, marginBottom: 15 }}>Marshal</h2>

                    {content.shio_profesi.marshal.map((item, index) => {
                      return (
                        <div style={{marginBottom:10}}>
                          <h3 style={{ margin: 0 }}>{item.name}</h3>
                          <span style={{ margin: 0 }}>{item.profesi}</span>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  marginBottom: 30,
                  paddingBottom: 30,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h1
                      style={{
                        color: content.content.color,
                        fontWeight: 700,
                        fontSize: "120%",
                        marginBottom: 10,
                        textAlign: "center",
                        color: content.content.color,
                      }}
                    >
                      ROLE MODEL - INDUSTRI
                    </h1>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 style={{ margin: 0, marginBottom: 15 }}>Property</h3>
                    <div>
                    <Table
                            className={classes.table}
                            style={{
                              backgroundColor: "#cccccc",
                            }}
                          >
                            
                            <TableBody>
                             
                    {content.shio_industri.Property.map((item, index) => {
                      return (
                       
                         
                                <TableRow>
                                  <TableCell align="left">
                                  {item.slug.toUpperCase()}
                                  </TableCell>
                                  <TableCell align="left"  style={{
                              backgroundColor: "#e5e5e5",
                            }}>
                                  {item.profesi}
                                  </TableCell>
                                  
                                </TableRow>
                            
                            
                      );
                    })}
                    </TableBody>
                          </Table>
                         
                        </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 style={{ margin: 0, marginBottom: 15 }}>Education</h3>
                    <div>
                          <Table
                            className={classes.table}
                            style={{
                              backgroundColor: "#cccccc",
                            }}
                          >
                            
                            <TableBody>
                    {content.shio_industri.Education.map((item, index) => {
                      return (
                        
                             
                                <TableRow>
                                  <TableCell align="left">
                                  {item.slug.toUpperCase()}
                                  </TableCell>
                                  <TableCell align="left"  style={{
                              backgroundColor: "#e5e5e5",
                            }}>
                                  {item.profesi}
                                  </TableCell>
                                  
                                </TableRow>
                            
                           
                      );
                    })}
                     </TableBody>
                          </Table>
                         
                        </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 style={{ margin: 0, marginBottom: 15 }}>Finance</h3>
                    <div>
                          <Table
                            className={classes.table}
                            style={{
                              backgroundColor: "#cccccc",
                            }}
                          >
                            
                            <TableBody>
                    {content.shio_industri.Finance.map((item, index) => {
                      return (
                        
                             
                                <TableRow>
                                  <TableCell align="left">
                                  {item.slug.toUpperCase()}
                                  </TableCell>
                                  <TableCell align="left"  style={{
                              backgroundColor: "#e5e5e5",
                            }}>
                                  {item.profesi}
                                  </TableCell>
                                  
                                </TableRow>
                            
                            
                      );
                    })}
                    </TableBody>
                          </Table>
                         
                        </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h3 style={{ margin: 0, marginBottom: 15 }}>Manufacture</h3>
                    <div>
                    <Table
                            className={classes.table}
                            style={{
                              backgroundColor: "#cccccc",
                            }}
                          >
                            
                            <TableBody>
                    {content.shio_industri.Manufacture.map((item, index) => {
                      return (
                      
                         
                             
                                <TableRow>
                                  <TableCell align="left">
                                  {item.slug.toUpperCase()}
                                  </TableCell>
                                  <TableCell align="left"  style={{
                              backgroundColor: "#e5e5e5",
                            }}>
                                  {item.profesi}
                                  </TableCell>
                                  
                                </TableRow>
                             
                            
                      );
                    })}
                    </TableBody>
                          </Table>
                         
                        </div>
                  </Grid>
                </Grid>
              </div>
              <div style={{ borderBottom: "1px solid #ccc", marginBottom: 30 }}>
                <img
                  src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/diagram.png"
                  alt=""
                  width="100%"
                />

                <div>
                  <span
                    style={{ color: "#ffffff;" }}
                    dangerouslySetInnerHTML={{
                      __html: `<table style="height: 392px; width: 100%;">
                            <tbody>
                            <tr style="background-color:#e5e5e5">
                            <td style="width: 15.5194%;" valign="top">
                            <p style="text-align: center;"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-ALCHEMIST.png" width="100" height="100"><br>ALCHEMIST</p>
                            </td>
                            <td style="width: 81.4806%;">
                            <p>Penyempurnaan yang baik, penyelesaian proses, menemukan cara yang lebih baik dab cerdas, meningkatkan efesiensi dan efektivitas produk/sitem yang ada.</p>
                            <p>Seseorang yang memilki kemampuan beradaptasi dengan menempatkan diri sesuai dengạn dimana ia berada. mampu untuk tetap merendah dan secara frontal berusaha untuk mengejar setiap peluang yang ada.</p>
                            </td>
                            </tr>
                            <tr>
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-WIZARD.png" width="100" height="100">WIZARD</td>
                            <td style="width: 81.4806%;">
                            <p>Konseptor yang þaik, penggagas hal baru, kreatif, inovatif, pencipta ide, pemikir out-of-the-box, spontan, tidak teratur, impulsif, baik untuk memulai sesuatu yang baru.</p>
                            <p>Seseorang yang pampu berinovasi secara berkelanjutan, seringkali kurang bisa dipahami oleh banyak orang disekitarnya, namun sanggup berpikir out-of-the-box, dalam mengbasilkan konsep-konsep baru.</p>
                            </td>
                            </tr>
                            <tr style="background-color:#e5e5e5">
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-BARD.png" width="100" height="100">BARD</td>
                            <td style="width: 81.4806%;">
                            <p>Promotor yang baik, pemasar yang berbakat, merek pribadi yang kuat, þergerak pat, butyh perhatian, bersinar, mampy menginspiraşi orang lain, individu yang kokoh.</p>
                            <p>Seseorang yang mampu memberi energi kepada orang-orang di sekelilingnya, mendukung dan menyebarkan semangat secara konsisten, sehingga mampu menyampaikan segala hal dengan penerimaan yang relatif mudah.</p>
                            </td>
                            </tr>
                            <tr>
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-PRIEST.png" width="100" height="100">KNIGHT</td>
                            <td style="width: 81.4806%;">
                            <p>Influencer yang baik, pemimpip yang karismatik, manajer pelaksana, penyelenggara, administrator, pendukung, baik dalam mencirtakan dan mengelola hubungan, baik dalam manajemen sumber daya manusia, fleksibel.</p>
                            <p>Seorang yang selalu mampu memberi semangat pada orang-orang disekitarnya, dimana api mampu menerangi kegelapan dan menyambar kemanapun, sebingga ia bisa mempengaruhi lebih banyak orang untuk sejalan seiring berjalannya waktu.</p>
                            </td>
                            </tr>
                            <tr style="background-color:#e5e5e5">
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-ARBITER.png" width="100" height="100">ARBITER</td>
                            <td style="width: 81.4806%;">
                            <p>Penengah yang baik, pembawa damai, representatif bijak, melihat alternatif sebagai manfaat, penghindar konflik, berinyestasi pada orang untuk manfaat jangka panjang.</p>
                            <p>Seseorang yang tegas şerta mampu menciptakan kemungkinan baru, walaupun sebelumnya dirasakan tidak ada jalan lain, dimana angin biasanya memang tidak terlibat namun bisa dirasakan kehadirannya, mengisi apapun dan dimanapun.</p>
                            </td>
                            </tr>
                            <tr>
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-MERCHANT.png" width="100" height="100">MERCHANT</td>
                            <td style="width: 81.4806%;">
                            <p>Negosiator ulung, pedagang yang handal, pencari peluang, pengguna waktu yang efektif dan efisien, fleksibel, bekerja dalam pola/parameter tertentų, berdasarkan data.</p>
                            <p>Seseorang yang mampu memanfaatkan kekuatan dari wawasan yg luas, yang menampung apapun dari segala penjuru, mencapai keberhasilan dengan menerima berbagai hal, dan memanfaatkannva untuk keberhasilan yang lebih besar.&nbsp;</p>
                            </td>
                            </tr>
                            <tr style="background-color:#e5e5e5">
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-KNIGHT.png" width="100" height="100">PRIEST</td>
                            <td style="width: 81.4806%;">
                            <p>Penjaga yang baik, dapat diandalkan, sabar, berhati-hati, berbasis fakta, safety- player, terstruktur, unggul dengan analisis informasi, cerdas dalam mengelola risiko.</p>
                            <p>Seseorang yang mampu menegakkan aturan secara tegas, disegani oleh orang- orang dibawahnya, dan ditakuti oleh siapapun yang melakukan pelanggaran aturan, dengan keteguhan tinggi sehingga mampu dijadikan sebagai tauladan (role-model).</p>
                            </td>
                            </tr>
                            <tr>
                            <td style="width: 15.5194%; text-align: center;" valign="top"><img src="https://mastery.sgp1.cdn.digitaloceanspaces.com/shiokaya/S-MARSHAL.png" width="100" height="100">MARSHAL</td>
                            <td style="width: 81.4806%;">
                            <p>Penganalisa yang baik, prediktif, lebih efektif di belakang layar, terencana, berkomitmen, pengatur yang handal, berkemauan keras, regulator yang baik.</p>
                            <p>Seseorang yang kokoh dan bisa diandalkan, terbuka untuk berbagai peluang yang lewat atau dilewatinya, dan mampu memanfaatkan apapun yang saat ini dimilikinya, termasuk memanfaatkan kekuatan waktu dan prioritas-prioritas yang tersusun dengan baik.</p>
                            </td>
                            </tr>
                            </tbody>
                            </table>`,
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    const { classes, call_action, history } = this.props;
    const { result_data, max } = this.state;
    if (typeof call_action.profile === "undefined") return null;
    let payload = call_action.profile;
    if (typeof payload === "undefined") return null;
    if (payload === null) return null;
    if (payload.status === 0) return this.accessDenied();
    if (typeof payload.content === "undefined") return null;

    let { content, result } = payload;
    if (this.state.page === 2) {
      return this.renderContent();
    }
    return (
      <div>
        <Header />

        <div
          className={classes.root}
          style={{
            webkitBoxSizing: "border-box" /* Safari/Chrome, other WebKit */,
            mozBoxSizing: "border-box" /* Firefox, other Gecko */,
            boxSizing: "border-box",
            padding: 10,
            paddingTop: 80,
          }}
        >
          <Grid
            container
            style={{
              maxWidth: 800,
              marginTop: 60,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Grid item xs={12} md={12}>
              <div>
                <h1>Selamat! Anda telah mengambil tindakan!</h1>
                <br />
                <div>
                  Salah satu awal dalam sebuah perjalanan mencapai kekayaan diri
                  adalah dengan memahami bahwa ada delapan cara dalam
                  menciptakan kekayaan, dan masing-masing cara ini punya aturan
                  tertentu. Ketika Anda bisa mengikuti aturan-aturan tersebut,
                  maka akan membuahkan hasil yang optimal dan secara alamiah,
                  sesuai dengan karakteristik diri Anda.
                </div>
                <br />
                <div>
                  Sebelum kita melanjutkan, ada 3 pertanyaan penting yang perlu
                  Anda tanyakan pada diri Anda sendiri, yaitu:
                </div>
                <br />
                <div>
                  <em>
                    1. Jalan menuju kekayaan manakah yang paling sesuai dengan
                    karakter dan kepribadian alami saya?
                  </em>
                  <br />
                  <br />
                </div>
                <div>
                  <em>
                    2. Langkah apa yang harus saya lakukan untuk menuju kearah
                    kekayaan yang saya inginkan?
                  </em>
                  <br />
                  <br />
                </div>
                <div>
                  <em>
                    3. Apa saja aturan yang perlu saya ikuti dalam rangka
                    penciptaan kekayaan tersebut?
                  </em>
                </div>
                <br />
                <div>
                  Di halaman-halaman <strong>Shio Kaya</strong> ini, Anda bisa
                  menemukan profil kekayaan Anda dan jawaban untuk pertanyaan di
                  atas. Halaman-halaman ini juga berisi beberapa panduan bagi
                  Anda, tentang cara berpikir yang sesuai, model interaksi, dan
                  tindakan yang dibutuhkan untuk mendapatkan hasil maksimal dari
                  profil <strong>Shio Kaya</strong> Anda.
                </div>
                <br />
                <div>
                  Perjalanan menuju kekayaan ini tentu saja membutuhkan
                  kejelasan, fokus, dan tindakan yang konsisten, namun yang
                  terpenting adalah bahwa Anda sudah memiliki peta jalan alamiah
                  anda dalam penciptaan kekayaan Anda.
                </div>
                <br />
                <div>
                  Jika menggunakan analogi seperti ini, Profil
                  <strong> Shio Kaya</strong> Anda adalah seperti kompas, yang
                  akan menunjukkan arah kemana Anda sebaiknya berjalan, untuk
                  menciptakan kekayaan dengan resistensi diri seminimal mungkin.
                </div>
                <br />
                <div>
                  Melakukan perjalanan penciptaan kekayaan Anda sendiri perlu
                  untuk terarah, jelas, dan fokus. <strong>Shio Kaya</strong>{" "}
                  sendiri lebih dari sekadar sistem profiling karakter pribadi
                  untuk menunjukkan siapa Anda. <strong>Shio Kaya</strong>{" "}
                  adalah kerangka kerja (<em>framework</em>) yang kuat untuk
                  menjadi patokan dalam bertindak, seperti sebuah peta. Peta ini
                  dapat menunjukkan bagaimana caranya menginvestasikan waktu dan
                  sumber daya lain yang Anda miliki dengan optimal untuk
                  mencapai target Anda.
                </div>
                <br />
                <div>
                  <strong>Shio Kaya</strong> berisi sejumlah arahan untuk bisa
                  menggunakan profil Anda, untuk memaksimalkan perjalanan Anda
                  menuju kekayaan. Silahkan menggunakan
                  <strong> Shio Kaya</strong> sebagai manual dan panduan
                  perjalanan menuju kekayaan, untuk memungkinkan Anda
                  mempercepat kemajuan secara efektif. Kami hadir di sini untuk
                  membantu Anda sepanjang perjalanan Anda menuju sukses.
                </div>
                <br />
                <div>
                  Terima kasih telah membuat keputusan untuk menginvestasikan
                  waktu Anda dengan bijak di masa depan Anda.
                </div>
                <br />

                <br />
                <img src="ttd.png" style={{ width: 200 }} />
              </div>
              <div style={{ margin: 30, textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ page: 2 });
                  }}
                >
                  LANJUT KE PROFILE ANDA
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { call_action, guestLogin } = state;
  return {
    call_action,
    guestLogin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginAsGuest: (data) => dispatch(loginAsGuestAction(data)),
  callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(QuizContainerStyles)(PremiumProfileTestContainer));
