import React from 'react';

export default function(props){
    const {value} = props;
    let arr = [];
    for(let i=0; i<value; i++){
        arr.push(1);
    }
    return (
        <div style={{paddingTop:0}}>

        
        {arr.map((item,index)=>{
            return (<svg key={index} width="30" height="30">
                 <rect width="30" height="30" style={{fill:'#ccc',strokeWidth:3,stroke:'rgb(0,0,0)'}} />
            </svg>);
        })}
        </div>
    )
}